import Box from '@mui/material/Box'
import WarningIcon from '../../Assets/Icons/warning.svg'
import { Colors } from '../../Utils/theme'

export default function InfoBox(props: any) {
  const { label, value } = props

  const getStyles = () => {
    let containerStyles: any = styles.container
    if (props?.sx) containerStyles = { ...containerStyles, ...props.sx }
    if (props.mb) containerStyles = { ...containerStyles, marginBottom: props.mb }
    if (props.mt) containerStyles = { ...containerStyles, marginTop: props.mt }
    if (props.mr) {
      containerStyles = {
        ...containerStyles,
        mr: {
          xl: props.mr,
          xs: props.mr
        }
      }
    }
    if (props.ml) containerStyles = { ...containerStyles, marginLeft: props.ml }
    if (props?.width) containerStyles = { ...containerStyles, width: props.width }
    if (props?.height) containerStyles = { ...containerStyles, height: props.height }
    if (props?.backgroundColor) containerStyles = { ...containerStyles, backgroundColor: props.backgroundColor }
    return containerStyles
  }

  const renderWarning = () => {
    if (props?.warning) {
      return <Box component='img' src={WarningIcon} alt='warning' sx={styles.icon} />
    }
    return null
  }

  return (
    <Box sx={getStyles()}>
      <Box sx={styles.label}>{label}</Box>
      <Box sx={styles.value}>{value || '-'}</Box>
      {renderWarning()}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: Colors.brandPrimary,
    padding: '0.5875rem 1rem',
    color: Colors.white,
    borderLeft: `0.625rem solid ${Colors.brandSecondary}`,
    mr: {
      xl: '2rem',
      xs: '1.5rem'
    }
  },
  label: {
    fontSize: {
      xl: '1rem',
      xs: '0.875rem'
    },
    fontWeight: 600,
    color: Colors.lightPurple,
  },
  value: {
    fontSize: {
      xl: '1.125rem',
      xs: '1rem'
    },
    fontWeight: 400,
    color: Colors.white,
  },
  icon: {
    position: 'absolute',
    right: '1.25rem',
    width: '1.5rem',
    height: '1.5rem',
  }
} as const
