import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class DiscStore {
  rootStore

  discs: any = []
  disc: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateDiscs = (discs: any) => { this.discs = discs }
  updateDisc = (disc: any) => { this.disc = disc }
  updateLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.updateDiscs([])
    this.updateDisc(null)
    this.updateLoading(false)
  }

  async getDiscs() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getDiscs()
      if (response.ok) {
        const discs = response.data?.items || []
        this.updateDiscs(discs)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getDisc(id: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getDisc(id)
      if (response.ok) {
        this.updateDisc(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateDiscInfo(data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateDisc(this?.disc?.id, data)
      if (response.ok) {
        this.getDiscs()
        showMessage(i18n.t('disc_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('disc_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async unlockDisc(id: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.unlockDisc(id)
      if (response.ok) {
        const disc = response?.data
        this.updateDisc(disc)
        this.getDiscs()
        showMessage(i18n.t('disc_unlocked'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('disc_unlock_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async transferDiscOwnership(discId: number, userId: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.transferDiscOwnership(discId, userId)
      if (response.ok) {
        const disc = response?.data
        this.updateDisc(disc)
        this.getDiscs()
        showMessage(i18n.t('disc_transferred'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('disc_transfer_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async setAsLost(id: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateDisc(id, { status: 'lost' })
      if (response.ok) {
        const disc = response?.data
        this.updateDisc(disc)
        this.getDiscs()
        showMessage(i18n.t('disc_set_as_lost'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('disc_set_as_lost_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async setAsFound(id: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateDisc(id, { status: 'in_use' })
      if (response.ok) {
        const disc = response?.data
        this.updateDisc(disc)
        this.getDiscs()
        showMessage(i18n.t('disc_set_as_found'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('disc_set_as_found_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveDisc(id: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveDisc(id)
      if (response.ok) {
        this.getDiscs()
        showMessage(i18n.t('disc_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('disc_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
