import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Discs = (props: any) => {
  const { sessionStore, discStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const gridApiRef = useGridApiRef()

  const { language } = sessionStore
  const { loading, discs } = discStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    discStore.getDiscs()
  }, [])

  const toDisc = (row: any) => navigate(`/discs/${row.id}`)
  const toMobileUser = (id: number) => navigate(`/users/mobile/${id}`)

  const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef)

  const downloadCsv = () => {
    gridApiRef.current.exportDataAsCsv({
      getRowsToExport: getFilteredRows,
      allColumns: true,
      fileName: `discs-${DateTime.now().toFormat('dd-MM-yyyy-HH-mm')}`,
      utf8WithBom: true
    })
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: t('disc_name'),
      flex: 1,
      minWidth: 260,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row?.archivedAt ? '-' : params?.row?.name?.trim() || '-'}
          onClick={() => toDisc(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.row?.archivedAt) return t('not_in_use')
        return t(params?.row?.status)
      },
      renderCell: (params: GridRenderCellParams) => {
        const status = params?.row?.archivedAt ?
          'not_in_use' :
          params?.row?.status === 'lost' ?
          'lost' :
          'in_use'
        return <Chip label={t(status)} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'createdAt',
      headerName: t('paired_at'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          const datetime = DateTime.fromISO(params.value)
          return datetime.toFormat('dd.MM.yyyy')
        }
        return '-'
      }
    },
    {
      field: 'mobileUser',
      headerName: t('user'),
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row?.mobileUserId || params.row?.archivedAt) return '-'
        return `${params.row?.firstName || ''} ${params.row?.lastName || ''}`.trim()
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params.row.mobileUserId || params.row?.archivedAt) return '-'
        return (
          <Button
            sx={styles.name}
            text={`${params.row.firstName} ${params.row.lastName}`.trim()}
            onClick={() => toMobileUser(params.row.mobileUserId)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'firmware',
      headerName: t('fw'),
      flex: 1,
      maxWidth: 100,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params?.value ? Number(params.value) : 0,
      valueFormatter: (params: GridValueFormatterParams) => params?.value || '-'
    },
    {
      field: 'hwUuid',
      headerName: t('mac'),
      flex: 1,
      minWidth: 180,
      maxWidth: 220,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params?.value || '-'
    },
    {
      field: 'throwCount',
      headerName: t('throw_count'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params?.value ? Number(params.value) : 0,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Chip
            label={`${params?.row?.throwCount} ${t('pcs')}`}
            variant='outlined'
            size='small'
            sx={styles.chip}
          />
        )
      }
    },
  ], [language])

  const rows = useMemo(() => {
    let foundDiscs = discStore.discs
    if (searchKey) {
      foundDiscs = foundDiscs
        .filter((disc: any) => `${disc?.name} ${disc?.hwUuid} ${!disc?.archivedAt ? (disc?.firstName || '') : '-'} ${!disc?.archivedAt ? (disc?.lastName || '') : '-'}`
        ?.toLowerCase()
        ?.includes(searchKey.toLowerCase()))
    }
    return foundDiscs
  }, [discs, searchKey, filters])

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        searchPlaceholder={t('search_by_name_or_mac')}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        csvDisabled={!rows.length}
        onCsvDownload={downloadCsv}
      />
      <Table
        rows={rows}
        columns={columns}
        loading={loading}
        apiRef={gridApiRef}
      />
    </Box>
  )
}

export default observer(Discs)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 8rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
} as const
