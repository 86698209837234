import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import uniq from 'lodash/uniq'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { DateTime } from 'luxon'
import {
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams,
  GridValueGetterParams,
  useGridApiRef,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const DiscThrows = () => {
  const { discStore, throwStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams()

  const gridApiRef = useGridApiRef()

  const { loading } = throwStore
  const { disc } = discStore

  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    'timestamp',
    'shotType',
    'fixedDistanceCm',
    'firmware',
    'mobileUserId',
    'hasGps',
    'hasRawData',
    'archivedAt'
  ])
  const [deleteThrowId, setDeleteThrowId] = useState<number | null>(null)

  const openActionConfirmation = (id: number) => setDeleteThrowId(id)
  const closeActionConfirmation = () => setDeleteThrowId(null)

  const updateVisibleColumns = (columns: string[]) => {
    let newCols = uniq(['timestamp', ...columns, 'archivedAt'])
    setVisibleColumns(newCols)
  }

  const ColumnOptions = [
    { value: 'shotType', label: t('type') },
    { value: 'fixedDistanceCm', label: t('mode') },
    { value: 'firmware', label: t('fw') },
    { value: 'mobileUserId', label: t('user') },
    { value: 'hasGps', label: t('gps') },
    { value: 'hasRawData', label: t('raw_data') },
    { value: 'distance', label: t('distance') },
    { value: 'armSpeed', label: t('arm_speed') },
    { value: 'flightSpeed', label: t('flight_speed') },
    { value: 'spin', label: t('spin') },
    { value: 'wobble', label: t('wobble') },
    { value: 'nose', label: t('nose') },
    { value: 'hyzer', label: t('hyzer') },
    { value: 'launch', label: t('launch') },
    { value: 'topG', label: t('max_g') },
    { value: 'quality', label: t('release') },
  ]
  
  const toThrow = (id: number) => navigate(`/throws/${id}`)
  const toMobileUser = (id: number) => navigate(`/users/mobile/${id}`)
  
  useEffect(() => {
    const discId = params?.discId ?? null
    if (discId) {
      throwStore.getDiscThrows(Number(discId))
    }
  }, [throwStore, params?.discId])

  // Table state
  const [searchKey, setSearchKey] = useState<string>('')

  const clearSearchKey = () => setSearchKey('')

  const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef)

  const downloadCsv = () => {
    gridApiRef.current.exportDataAsCsv({
      getRowsToExport: getFilteredRows,
      allColumns: true,
      fields: visibleColumns.filter((col) => col !== 'archivedAt'),
      fileName: `${disc?.name}-throws-${DateTime.now().toFormat('dd-MM-yyyy-HH-mm')}`,
      utf8WithBom: true
    })
  }
  
  const archiveThrow = () => {
    if (params?.discId && deleteThrowId) {
      throwStore.archiveThrow(
        deleteThrowId,
        () => {
          throwStore.getDiscThrows(Number(params.discId))
          closeActionConfirmation()
        }
      )
    }
  }

  const columns: GridColDef<any>[] = useMemo(
    () => [
      {
        field: 'timestamp',
        headerName: t('timestamp'),
        headerClassName: 'gameproofer-table--header',
        flex: 1,
        minWidth: 220,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          const dayPart = DateTime.fromMillis(Number(params?.row?.timestamp)).toFormat('dd.MM.yyyy')
          const timePart = DateTime.fromMillis(Number(params?.row?.timestamp)).toFormat('HH.mm')
          const date = `${dayPart} ${t('at')} ${timePart}`
          return date
        },
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Button
              sx={styles.name}
              text={params?.value}
              onClick={() => toThrow(params.row.id)}
              variant='text'
            />
          )
        }
      },
      visibleColumns.includes('shotType') && ({
        field: 'shotType',
        headerName: t('type'),
        headerClassName: 'gameproofer-table--header',
        flex: 1,
        maxWidth: 140,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Chip
              label={`${params?.row?.shotType ? t(params.row.shotType) : '-'}`}
              variant='outlined'
              size='small'
              sx={styles.chip}
            />
          )
        }
      }),
      visibleColumns.includes('fixedDistanceCm') && ({
        field: 'fixedDistanceCm',
        headerName: t('mode'),
        headerClassName: 'gameproofer-table--header',
        flex: 1,
        maxWidth: 140,
        disableColumnMenu: true,
        valueGetter: (params: GridRenderCellParams<any>) => {
          return params?.value ? 'range' : 'field'
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Chip
              label={!params?.row?.fixedDistanceCm ? t('field') : t('range')}
              variant='outlined'
              size='small'
              sx={styles.chip}
            />
          )
        }
      }),
      visibleColumns.includes('firmware') && ({
        field: 'firmware',
        headerName: t('fw'),
        headerClassName: 'gameproofer-table--header',
        flex: 1,
        maxWidth: 120,
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('mobileUserId') && ({
        field: 'mobileUserId',
        headerName: t('user'),
        flex: 1,
        minWidth: 200,
        maxWidth: 300,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          if (!params.row?.mobileUserId) return '-'
          return `${params?.row?.firstName || ''} ${params?.row?.lastName || ''}`.trim()
        },
        renderCell: (params: GridRenderCellParams<any>) => {
          if (!params.row.mobileUserId) return '-'
          return (
            <Button
              sx={styles.name}
              text={`${params?.row?.firstName || ''} ${params?.row?.lastName || ''}`.trim()}
              onClick={() => toMobileUser(params.row.mobileUserId)}
              variant='text'
            />
          )
        }
      }),
      visibleColumns.includes('hasGps') && ({
        field: 'hasGps',
        headerName: t('gps'),
        flex: 1,
        maxWidth: 120,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return Number(params?.value) ? t('yes') : t('no')
        }
      }),
      visibleColumns.includes('hasRawData') && ({
        field: 'hasRawData',
        headerName: t('raw_data'),
        flex: 1,
        maxWidth: 160,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return Number(params?.value) ? t('yes') : t('no')
        }
      }),
      visibleColumns.includes('distance') && ({
        field: 'distance',
        headerName: t('distance'),
        flex: 1,
        maxWidth: 160,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          let distance = params?.row?.distance
          if (params?.row?.fixedDistanceCm) {
            distance = params?.row?.fixedDistanceCm / 100
          }
          return distance || '-'
        },
        renderCell: (params: GridRenderCellParams) => {
          let distance = params?.row?.distance
          if (params?.row?.fixedDistanceCm) {
            distance = params?.row?.fixedDistanceCm / 100
          }
          return distance || '-'
        }
      }),
      visibleColumns.includes('armSpeed') && ({
        field: 'armSpeed',
        headerName: t('arm_speed'),
        flex: 1,
        maxWidth: 160,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('flightSpeed') && ({
        field: 'flightSpeed',
        headerName: t('flight_speed'),
        flex: 1,
        maxWidth: 175,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('spin') && ({
        field: 'spin',
        headerName: t('spin'),
        flex: 1,
        maxWidth: 120,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('wobble') && ({
        field: 'wobble',
        headerName: t('wobble'),
        flex: 1,
        maxWidth: 130,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('nose') && ({
        field: 'nose',
        headerName: t('nose'),
        flex: 1,
        maxWidth: 120,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('hyzer') && ({
        field: 'hyzer',
        headerName: t('hyzer'),
        flex: 1,
        maxWidth: 120,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('launch') && ({
        field: 'launch',
        headerName: t('launch'),
        flex: 1,
        maxWidth: 140,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('topG') && ({
        field: 'topG',
        headerName: t('max_g'),
        flex: 1,
        maxWidth: 120,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      visibleColumns.includes('quality') && ({
        field: 'quality',
        headerName: t('release'),
        flex: 1,
        maxWidth: 140,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params?.value || '-'
        }
      }),
      {
        field: 'archivedAt',
        headerName: t('actions'),
        flex: 1,
        maxWidth: 200,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        sortable: false,
        exportable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params?.row?.archivedAt) {
            return null
          }
          return (
            <Box sx={styles.actions}>
              <ButtonBase onClick={() => openActionConfirmation(params?.row?.id)}>
                <Chip
                  label={t('delete')}
                  variant='outlined'
                  size='small'
                  sx={styles.buttonChip}
                />
              </ButtonBase>
            </Box>
          )
        }
      }
    ].filter(Boolean) as GridColDef<any>[],
    [visibleColumns]
  )

  const getRowId = (item: any) => `${item.id}`

  const rows = useMemo(() => {
    let throws = throwStore.throws

    const searchFields: any[] = [
      'shotType',
      'firstName',
      'lastName'
    ]

    if (searchKey) {
      throws = throws.filter((field: any) => {
        for (const key of searchFields) {
          const value = field?.[key] ?? field?.[key?.split('.')[0]]?.[key?.split('.')[1]] ?? ''
          if (typeof value === 'string' && value.toLowerCase().includes(searchKey.toLowerCase())) {
            return true
          }
        }
        return false
      })
    }
    return throws
  }, [throwStore.throws, searchKey, loading])

  const renderConfirmationDialog = () => {
    if (deleteThrowId) {
      return (
        <ConfirmationDialog
          title={t('delete_throw')}
          content={t('delete_throw_confirmation')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archiveThrow}
          actionText={t('delete')}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        columnOptions={ColumnOptions}
        onSelectColumns={updateVisibleColumns}
        columns={visibleColumns}
        csvDisabled={!rows.length}
        onCsvDownload={downloadCsv}
      />
      <Table
        rows={rows}
        columns={columns}
        /*
        mobileColumns={{
          sm: ['id', 'timestamp'],
          xs: ['id', 'timestamp']
        }}
        */
        getRowId={getRowId}
        apiRef={gridApiRef}
        loading={loading}
      />
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(DiscThrows)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 8rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
} as const
