import { makeAutoObservable } from 'mobx'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import { Api } from '../Services'
import { Event, EventParticipant } from '../Types/events'
import { UUID } from '../Types/common'

export default class EventStore {
  rootStore

  events: Event[] = []
  event: Event | null = null
  throws: any[] = []
  eventParticipants: EventParticipant[] = []
  loading: boolean = false
  loadingParticipants: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateEvents = (events: Event[]) => { this.events = events }
  updateEvent = (event: Event | null) => { this.event = event }
  updateThrows = (throws: any[]) => { this.throws = throws }
  updateEventParticipants = (eventParticipants: EventParticipant[]) => { this.eventParticipants = eventParticipants }
  updateLoading = (loading: boolean) => { this.loading = loading }
  updateLoadingParticipants = (loading: boolean) => { this.loadingParticipants = loading }

  reset() {
    this.updateEvents([])
    this.updateEvent(null)
    this.updateThrows([])
    this.updateEventParticipants([])
    this.updateLoading(false)
    this.updateLoadingParticipants(false)
  }

  async getEvents() {
    this.updateLoading(true)
    try {
      const response = await Api.getEvents()
      if (response.ok && response?.data) {
        const events = response.data
        this.updateEvents(events)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getEvent(eventUuid: UUID) {
    this.updateLoading(true)
    try {
      const response = await Api.getEvent(eventUuid)
      if (response.ok && response.data) {
        this.updateEvent(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getEventThrows(eventUuid: UUID) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getEventThrows(eventUuid)
      if (response.ok && response.data) {
        const event = response.data?.event ?? null
        const throws = response.data?.throws ?? []
        this.updateEvent(event)
        this.updateThrows(throws)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createEvent(
    event: Partial<Event>,
    callback: (params: { success: boolean; event?: Event }) => void
  ) {
    this.updateLoading(true)
    try {
      const response = await Api.createEvent(event)
      if (response.ok && response?.data) {
        const event = response.data
        this.updateEvent(event)
        showMessage(i18n.t('event_created'))
        console.log(event)
        callback({ success: true, event })
      } else {
        showMessage(i18n.t('event_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
    callback({ success: false })
  }

  async editEvent(eventUuid: UUID, event: Partial<Event>) {
    this.updateLoading(true)
    try {
      const response = await Api.updateEvent(eventUuid, event)
      if (response.ok && response?.data) {
        this.updateEvent(response.data)
        showMessage(i18n.t('event_updated'))
      } else {
        showMessage(i18n.t('event_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateEventPhoto(eventUuid: UUID, file: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateEventPhoto(eventUuid, file)
      if (response.ok) {
        const event = response.data ?? null
        this.updateEvent(event)
        showMessage(i18n.t('event_photo_updated'))
      } else {
        showMessage(i18n.t('event_photo_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveEventPhoto(eventUuid: UUID, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveEventPhoto(eventUuid)
      if (response.ok) {
        const event: any = response.data ?? null
        this.updateEvent(event)
        showMessage(i18n.t('event_photo_deleted'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('event_photo_delete_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getEventParticipants(eventUuid: UUID) {
    this.updateLoadingParticipants(true)
    try {
      const response = await Api.getEventParticipants(eventUuid)
      if (response.ok && response.data) {
        this.updateEventParticipants(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoadingParticipants(false)
  }

  async setEventActiveThrower(eventUuid: UUID, mobileUserId: number) {
    try {
      const response = await Api.setEventActiveThrower(eventUuid, mobileUserId)
      if (response.ok) {
        showMessage(i18n.t('event_text.active_thrower_changed'))
      } else {
        showMessage(i18n.t('event_text.active_thrower_change_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async removeEventActiveThrower(eventUuid: UUID) {
    try {
      const response = await Api.removeEventActiveThrower(eventUuid)
      if (response.ok) {
        showMessage(i18n.t('event_text.active_thrower_removed'))
        if (this.event) {
          this.updateEvent({ ...this.event, activeThrower: null, customActiveThrowerName: null })
        }
      } else {
        showMessage(i18n.t('event_text.active_thrower_remove_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async setEventManager(eventUuid: UUID, mobileUserEmail: string) {
    try {
      const response = await Api.updateEventManager(eventUuid, mobileUserEmail)
      if (response.ok) {
        showMessage(i18n.t('event_text.event_manager_set'))
        this.getEvent(eventUuid)
      } else {
        showMessage(i18n.t('event_text.event_manager_set_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async removeEventManager(eventUuid: UUID) {
    try {
      const response = await Api.removeEventManager(eventUuid)
      if (response.ok) {
        showMessage(i18n.t('event_text.event_manager_removed'))
        this.getEvent(eventUuid)
      } else {
        showMessage(i18n.t('event_text.event_manager_remove_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async archiveEventThrow(eventUuid: UUID, throwId: number, callback?: any) {
    this.updateLoading(true)
    try {
      console.log('got here')
      const response = await Api.archiveEventThrow(eventUuid, throwId)
      console.log('response', response)
      if (response.ok) {
        showMessage(i18n.t('event_text.throw_archived'))
        await this.getEventThrows(eventUuid)
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('event_text.throw_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
