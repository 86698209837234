import { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Box, Paper, Typography, Fade } from '@mui/material'
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps'
import { PathLayer, ScatterplotLayer, IconLayer } from '@deck.gl/layers'
import { GoogleMapsApiKey } from '../../Utils/constants'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import type { LayersList, Layer } from '@deck.gl/core'
import UserIcon from '../../Assets/Icons/user.svg'
import FrisbeeIcon from '../../Assets/Icons/frisbee.svg'
import { Colors } from '../../Utils/theme'

type FlightPoint = {
  lat: number;
  lng: number;
  alt: number;
  heading?: number;
  hyzer?: number;
  nose?: number;
  flightSpeed?: number;
  spin?: number;
  distance?: number;
  timestamp?: number;
}

type DataPoint = {
  label: string;
  value: number | undefined;
  unit: string;
}

export type DeckGlOverlayProps = { layers?: LayersList }

const DeckGlOverlay = ({ layers }: DeckGlOverlayProps) => {
  const deck = useMemo(() => new GoogleMapsOverlay({}), [])
  const map = useMap()

  useEffect(() => {
    if (map) {
      deck.setMap(map)
      return () => deck.setMap(null)
    }
  }, [deck, map])

  useEffect(() => {
    deck.setProps({ layers })
  }, [deck, layers])

  return null
}

const MapEventsHandler = ({ onZoomChange }: { onZoomChange: (z: number) => void }) => {
  const map = useMap()
  
  useEffect(() => {
    if (!map) return
    const listener = map.addListener('zoom_changed', () => {
      const currentZoom = map.getZoom()
      if (typeof currentZoom === 'number') {
        onZoomChange(currentZoom)
      }
    })
    return () => {
      google.maps.event.removeListener(listener)
    }
  }, [map, onZoomChange])

  return null
}

const HoverCard = ({ info, totalDuration }: { info: any, totalDuration: number }) => {
  const formatValue = (value: number | undefined) => {
    if (value === undefined) return undefined
    return value
  }

  const formatTimestamp = (timestamp: number | undefined) => {
    if (!timestamp && timestamp !== 0) {
      if (totalDuration) {
        return `${(Number(totalDuration) / 1000).toFixed(1)} s`
      }
    }
    return `${timestamp} s`
  }

  const dataPoints: DataPoint[] = [
    { label: 'Speed', value: info.data.flightSpeed, unit: 'km/h' },
    { label: 'Distance', value: info.data.distance, unit: 'm' },
    { label: 'Spin', value: info.data.spin, unit: 'rpm' },
    { label: 'Wobble', value: info.data.wobble, unit: 'deg' },
    { label: 'Nose', value: info.data.nose, unit: 'deg' },
    { label: 'Hyzer', value: info.data.hyzer, unit: 'deg' },
  ].filter(point => point.value !== undefined)

  if (dataPoints.length === 0) return null

  return (
    <Fade in={true} timeout={200}>
      <Paper elevation={3} sx={styles.hoverCard}>
        <Typography variant='subtitle2' sx={styles.hoverTitle}>
          {formatTimestamp(info.data.timestamp)}
        </Typography>
        <Box sx={styles.dataGrid}>
          {dataPoints.map((point, index) => (
            <Box key={index} sx={styles.dataCell}>
              <Typography sx={styles.dataLabel}>{point.label}</Typography>
              <Typography variant='body2' sx={styles.dataValue}>
                {formatValue(point.value)} {point.unit}
              </Typography>
            </Box>
          ))}
        </Box>
      </Paper>
    </Fade>
  )
}

function getFlightLayers(flightData: FlightPoint[], hoverHandler: (info: any) => void, zoom: number): Layer[] {
  const flightPath: [number, number][] = flightData.map((dp): [number, number] => [dp.lng, dp.lat])
  const baseRadius = 3
  const adjustedRadius = baseRadius * Math.max(0.5, Math.min(1.2, zoom / 12))
  const hoverRadius = adjustedRadius * 4

  // Hover layer
  const hoverLayer = new ScatterplotLayer({
    id: 'hover-layer',
    data: flightData,
    getPosition: (d: any) => [d.lng, d.lat],
    getRadius: hoverRadius,
    radiusMinPixels: 15,
    radiusMaxPixels: 30,
    pickable: true,
    visible: true,
    opacity: 0,
    getFillColor: [0, 0, 0, 0],
    onHover: hoverHandler,
    getCursor: () => 'pointer'
  })

  // Main path
  const pathLayer = new PathLayer({
    id: 'flight-path-layer',
    data: [{ path: flightPath }],
    getPath: (d: any) => d.path,
    getColor: new Uint8Array([76, 1, 80]), // #4C0150
    widthScale: 3,
    widthMinPixels: 3,
    widthMaxPixels: 4,
    rounded: true,
    pickable: false
  })

  // Points layer
  const pointsLayer = new ScatterplotLayer({
    id: 'flight-points-layer',
    data: flightData,
    getPosition: (d: any) => [d.lng, d.lat],
    getRadius: adjustedRadius,
    radiusMinPixels: 1.5,
    radiusMaxPixels: 4,
    pickable: false,
    opacity: 0.3,
    getFillColor: new Uint8Array([146, 71, 150]) // #924796
  })

  // Start and End Points
  const startPointLayer = new ScatterplotLayer({
    id: 'start-point-layer',
    data: flightData.length > 0 ? [flightData[0]] : [],
    getPosition: (d: any) => [d.lng, d.lat],
    getRadius: adjustedRadius * 3,
    radiusMinPixels: 3,
    radiusMaxPixels: 6,
    pickable: false,
    opacity: 1,
    getFillColor: new Uint8Array([146, 71, 150]), // #924796
    strokeWidth: 1,
    stroked: true,
    getLineColor: new Uint8Array([76, 1, 80]) // #4C0150
  })

  const endPointLayer = new ScatterplotLayer({
    id: 'end-point-layer',
    data: flightData.length > 1 ? [flightData[flightData.length - 1]] : [],
    getPosition: (d: any) => [d.lng, d.lat],
    getRadius: adjustedRadius * 3,
    radiusMinPixels: 3,
    radiusMaxPixels: 6,
    pickable: false,
    opacity: 1,
    getFillColor: new Uint8Array([146, 71, 150]), // #924796
    strokeWidth: 1,
    stroked: true,
    getLineColor: new Uint8Array([76, 1, 80]) // #4C0150
  })

  // Icon layers for start and end points
  const startIconLayer = new IconLayer({
    id: 'start-icon-layer',
    data: flightData.length > 0 ? [{ ...flightData[0] }] : [],
    getPosition: (d: any) => [d.lng, d.lat],
    getIcon: () => ({
      url: UserIcon,
      width: adjustedRadius * 6,
      height: adjustedRadius * 6,
      anchorX: adjustedRadius * 3, // Half of the width
      anchorY: adjustedRadius * 3 // Half of the height, centers the icon on the point
    }),
    getSize: () => adjustedRadius * 4,
    sizeScale: 1.0,
    sizeMinPixels: adjustedRadius * 2,
    sizeMaxPixels: adjustedRadius * 8,
    billboard: false,
    pickable: false
  })

  const endIconLayer = new IconLayer({
    id: 'end-icon-layer',
    data: flightData.length > 1 ? [{ ...flightData[flightData.length - 1] }] : [],
    getPosition: (d: any) => [d.lng, d.lat],
    getIcon: () => ({
      url: FrisbeeIcon,
      width: adjustedRadius * 6,
      height: adjustedRadius * 6,
      anchorX: adjustedRadius * 3,
      anchorY: adjustedRadius * 3
    }),
    getSize: () => adjustedRadius * 4,
    sizeScale: 1.0,
    sizeMinPixels: adjustedRadius * 2,
    sizeMaxPixels: adjustedRadius * 8,
    billboard: false,
    pickable: false
  })

  const layers: Layer[] = [
    hoverLayer,
    pathLayer,
    pointsLayer,
    startPointLayer,
    endPointLayer,
    startIconLayer,
    endIconLayer
  ]

  return layers
}

function FitBounds({ flightData, mapLoaded }: { flightData: FlightPoint[], mapLoaded: boolean }) {
  const map = useMap()

  useEffect(() => {
    if (map && mapLoaded && flightData.length > 1) {
      const bounds = new google.maps.LatLngBounds()
      flightData.forEach((dp) => {
        bounds.extend({ lat: dp.lat, lng: dp.lng })
      })
      
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const latPadding = (ne.lat() - sw.lat()) * 0.25
      const lngPadding = (ne.lng() - sw.lng()) * 0.25
      
      bounds.extend({ lat: ne.lat() + latPadding, lng: ne.lng() + lngPadding })
      bounds.extend({ lat: sw.lat() - latPadding, lng: sw.lng() - lngPadding })

      map.fitBounds(bounds, {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      })
    }
  }, [map, mapLoaded, flightData])

  return null
}

export default function FlightMap2D(props: {
  flightData: FlightPoint[],
  startLocation: any,
  endLocation: any,
  totalDuration: number
}) {
  const { flightData, startLocation, endLocation, totalDuration } = props

  const [mapLoaded, setMapLoaded] = useState(false)
  const [hoverInfo, setHoverInfo] = useState<any>(null)
  const [zoom, setZoom] = useState(8)
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const hoverContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timer = setTimeout(() => setMapLoaded(true), 500)
    return () => clearTimeout(timer)
  }, [flightData])

  const handleHover = useCallback((info: any) => {
    if (info.object) {
      setHoverInfo({ x: info.x, y: info.y, data: info.object })
    } else {
      setHoverInfo(null)
    }
  }, [])

  const layers = useMemo(() => getFlightLayers(flightData, handleHover, zoom), [flightData, handleHover, zoom])

  const initialCenter = useMemo(() => {
    if (flightData.length > 0) {
      return { lat: flightData[0].lat, lng: flightData[0].lng }
    } else if (startLocation?.lat && startLocation?.lng) {
      return { lat: startLocation.lat, lng: startLocation.lng }
    } else if (endLocation?.lat && endLocation?.lng) {
      return { lat: endLocation.lat, lng: endLocation.lng }
    }
    return { lat: 0, lng: 0 }
  }, [flightData, startLocation, endLocation])

  // Adjust hover container position if it would go out of map boundaries
  const getHoverPosition = () => {
    if (!hoverInfo || !mapContainerRef.current || !hoverContainerRef.current) return {}
    const { x, y } = hoverInfo
    const mapRect = mapContainerRef.current.getBoundingClientRect()
    const hoverRect = hoverContainerRef.current.getBoundingClientRect()

    let left = x + 10
    let top = y + 10

    // If going off right edge
    if (left + hoverRect.width > mapRect.width) {
      left = x - hoverRect.width - 10
    }
    // If going off bottom edge
    if (top + hoverRect.height > mapRect.height) {
      top = y - hoverRect.height - 10
    }

    // Ensure it doesn't go off the left/top edge
    if (left < 0) left = 0
    if (top < 0) top = 0

    return { left, top }
  }

  const hoverStyle = useMemo(() => getHoverPosition(), [hoverInfo])

  return (
    <Box sx={styles.map} ref={mapContainerRef}>
      <APIProvider apiKey={GoogleMapsApiKey}>
        <Map
          defaultCenter={initialCenter}
          defaultZoom={8}
          mapId='flightMap'
          mapTypeId='satellite'
          gestureHandling='greedy'
        >
          <MapEventsHandler onZoomChange={setZoom} />
          {flightData.length > 0 && mapLoaded && <DeckGlOverlay layers={layers} />}
          <FitBounds flightData={flightData} mapLoaded={mapLoaded} />
        </Map>
      </APIProvider>
      {hoverInfo && (
        <Box
          ref={hoverContainerRef}
          sx={{
            ...styles.hoverContainer,
            position: 'absolute',
            pointerEvents: 'none',
            zIndex: 1000,
            ...hoverStyle
          }}
        >
          <HoverCard info={hoverInfo} totalDuration={totalDuration} />
        </Box>
      )}
    </Box>
  )
}

const styles = {
  map: {
    width: '100%',
    height: '30rem',
    position: 'relative',
    borderRadius: '0.625rem',
    overflow: 'hidden',
    marginBottom: '1.25rem'
  },
  hoverContainer: {
    transform: 'translate(0px, 0px)'
  },
  hoverCard: {
    px: '0rem',
    py: '0.625rem',
    backgroundColor: 'rgba(255, 255, 255, 0.98)',
    backdropFilter: 'blur(8px)',
    borderRadius: '8px',
    border: '1px solid rgba(76, 1, 80, 0.1)',
    width: '15rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  hoverTitle: {
    color: Colors.brandPrimary,
    fontWeight: 600,
    textAlign: 'center',
    mb: '0.5rem',
    borderBottom: '1px solid rgba(76, 1, 80, 0.1)',
    pb: '0.5rem'
  },
  dataGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 0.5,
    width: '100%',
    px: 0.5
  },
  dataCell: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  dataLabel: {
    fontSize: '0.875rem',
    fontWeight: 700,
    color: Colors.brandPrimary
  },
  dataValue: {
    fontWeight: 600
  }
} as const
