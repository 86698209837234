import { makeAutoObservable } from 'mobx'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import { Api } from '../Services'

export default class UtilsStore {
  rootStore

  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateLoading = (loading: boolean) => { this.loading = loading }

  async searchForLocation(query: string) {
    this.updateLoading(true)
    try {
      const response = await Api.searchForLocation(query)
      if (response.ok) {
        return response.data
      } else {
        showMessage(i18n.t('location_search_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

}
