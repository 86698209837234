import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Input, Select } from '../../../Components'
import Map from '../../../Components/Common/Map'
import Checkbox from '../../../Components/Common/Checkbox'
// import { ISODate } from '../../../Types/common'
// import DatePicker from '../../../Components/Common/DatePicker'
import TimeInput from '../../../Components/Common/TimeInput'
import TextEditor from '../../../Components/Common/TextEditor'
import { CountryOptions } from '../../../Utils/constants'
import {
  Event as EventType,
  SkillLevel,
  ThrowDataItem,
  Gender
} from '../../../Types/events'
import { Colors } from '../../../Utils/theme'

const SkillLevels: SkillLevel[] = [SkillLevel.All, SkillLevel.Pro, SkillLevel.Amateur]
const Genders: Gender[] = [Gender.All, Gender.Male, Gender.Female]
const ThrowDataItems: ThrowDataItem[] = Object.values(ThrowDataItem)

const CreateEvent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { eventStore, utilsStore } = useStore()

  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState<any>(null)
  const [startTime, setStartTime] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)
  const [description, setDescription] = useState('')
  const [descriptionHtml, setDescriptionHtml] = useState('')
  const [instructions, setInstructions] = useState('')
  const [instructionsBeforeEvent, setInstructionsBeforeEvent] = useState('')
  const [instructionsDuringEvent, setInstructionsDuringEvent] = useState('')
  const [place, setPlace] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('Suomi')
  const [longitude, setLongitude] = useState('')
  const [latitude, setLatitude] = useState('')
  const [skillLevel, setSkillLevel] = useState<SkillLevel[]>([])
  const [gender, setGender] = useState<Gender[]>([])
  const [throwData, setThrowData] = useState<ThrowDataItem[]>([])
  const [hiddenInApp, setHiddenInApp] = useState(false)

  const toggleHiddenInApp = () => setHiddenInApp(!hiddenInApp)

  const save = () => {
    const startsAt = DateTime
      .fromFormat(`${startDate} ${startTime}`, `yyyy-MM-dd HH:mm`)
      .toISO() ?? undefined
    const endsAt = DateTime
      .fromFormat(`${endDate} ${endTime}`, `yyyy-MM-dd HH:mm`)
      .toISO() ?? undefined
    const event: Partial<EventType> = {
      name,
      startsAt,
      endsAt,
      description,
      descriptionHtml,
      instructions,
      instructionsBeforeEvent,
      instructionsDuringEvent,
      place,
      streetAddress,
      postalCode,
      city,
      country,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      skillLevel,
      gender,
      throwData,
      hiddenInApp
    }

    eventStore.createEvent(event, ({ success, event }) => {
      if (success && event) {
        navigate(`/events/${event.uuid}`)
      }
    })
  }

  const handleSkillLevelChange = (value: SkillLevel) => {
    if (skillLevel.includes(value)) {
      setSkillLevel(skillLevel.filter((level) => level !== value))
    } else {
      setSkillLevel([...skillLevel, value])
    }
  }

  const handleGenderChange = (value: Gender) => {
    if (gender.includes(value)) {
      setGender(gender.filter((gender) => gender !== value))
    } else {
      setGender([...gender, value])
    }
  }

  const handleThrowDataChange = (value: ThrowDataItem) => {
    if (throwData.includes(value)) {
      setThrowData(throwData.filter((dataItem) => dataItem !== value))
    } else {
      setThrowData([...throwData, value])
    }
  }

  const searchForLocation = async () => {
    let address = `${streetAddress}, ${postalCode} ${city}`
    if (country) {
      address = `${address}, ${country}`
    }
    const location: any = await utilsStore.searchForLocation(address)
    if (location && location?.latitude && location?.longitude) {
      setLatitude(location.latitude)
      setLongitude(location.longitude)
    }
  }

  const renderSkillLevelOptions = () => {
    return SkillLevels.map((value) => (
      <Checkbox
        key={value}
        label={t(`skill_level_option.${value}`)}
        checked={skillLevel.includes(value)}
        onChange={() => handleSkillLevelChange(value)}
      />
    ))
  }

  const renderGenderOptions = () => {
    return Genders.map((value) => (
      <Checkbox
        key={value}
        label={t(`gender_option.${value}`)}
        checked={gender.includes(value)}
        onChange={() => handleGenderChange(value)}
      />
    ))
  }

  const renderThrowDataOptions = () => {
    return ThrowDataItems.map((value, index: number) => (
      <Checkbox
        key={value}
        label={t(`throw_data_item.${value}`)}
        checked={throwData.includes(value)}
        onChange={() => handleThrowDataChange(value)}
        width='33.3333%'
        mb={index < 6 ? '0.75rem' : '0'}
      />
    ))
  }

  const renderCity = () => {
    /*
    if (country === 'Suomi') {
      return (
        <Select
          label={t('city')}
          options={FinnishMunicipalityOptions}
          value={city}
          onChange={setCity}
        />
      )
    } else {
      return <Input label={t('city')} value={city} onChange={setCity} />
    }
    */
    return <Input label={t('city')} value={city} onChange={setCity} />
  }

  const renderMap = () => {
    if (latitude && longitude && parseFloat(latitude) && parseFloat(longitude)) {
      return (
        <>
          <Typography variant='body2' sx={styles.label}>{t('on_map')}</Typography>
          <Map location={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }} />
        </>
      )
    }
    return null
  }
  
  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
      <Typography variant='h1' mb='2rem'>{t('create_new_event')}</Typography>
        <Box sx={styles.formContainer}>
          <Input label={t('event_name')} value={name} onChange={setName} mb='1.5rem' />
          <Box sx={styles.row}>
            <Box sx={styles.timesContainer}>
              <Input
                label={t('starts_at')}
                value={startDate}
                onChange={setStartDate}
                width='10rem'
                type='date'
              />
              <TimeInput
                value={startTime}
                onChange={setStartTime}
                width='5.625rem'
                mt='1.925rem'
                mr='1rem'
              />
            </Box>
            <Box sx={styles.separator}>-</Box>
            <Box sx={styles.timesContainer}>
              <Input
                label={t('ends_at')}
                value={endDate}
                onChange={setEndDate}
                width='10rem'
                type='date'
                ml='1rem'
              />
              <TimeInput
                value={endTime}
                onChange={setEndTime}
                width='5.625rem'
                mt='1.925rem'
              />
            </Box>
          </Box>
          <TextEditor
            label={t('description')}
            value={descriptionHtml}
            onChange={setDescriptionHtml}
          />
        </Box>
        <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
          {t('event_text.scoreboard_settings')}
        </Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Box display='flex' flexDirection='column' mr='2rem'>
              <Typography variant='body1' sx={styles.label}>
                {t('event_text.skill_level_title')}
              </Typography>
              <Box sx={styles.optionsContainer}>
                {renderSkillLevelOptions()}
              </Box>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1' sx={styles.label}>
                {t('event_text.gender_title')}
              </Typography>
              <Box sx={styles.optionsContainer}>
                {renderGenderOptions()}
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='body1' sx={styles.label}>
              {t('event_text.throw_data_title')}
            </Typography>
            <Box sx={{ ...styles.optionsContainer, width: '43rem' }}>
              {renderThrowDataOptions()}
            </Box>
          </Box>
        </Box>
        <Typography variant='h2' mb='1.5rem' mt='2.5rem'>{t('location')}</Typography>
        <Box sx={styles.formContainer}>
         <Input
            label={t('event_place')}
            value={place}
            onChange={setPlace}
            mb='1.5rem'
          />
          <Input
            label={t('street_address')}
            value={streetAddress}
            onChange={setStreetAddress}
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Input
              label={t('postal_code')}
              value={postalCode}
              onChange={setPostalCode}
              maxWidth='12rem'
              mr='2rem'
            />
            {renderCity()}
          </Box>
          <Select
            label={t('country')}
            options={CountryOptions}
            value={country}
            onChange={setCountry}
            maxWidth='24rem'
            mb='1.5rem'
          />
          <Box sx={{ ...styles.row, justifyContent: 'flex-start' }}>
            <Input
              label={t('latitude')}
              value={latitude}
              onChange={setLatitude}
              maxWidth='9rem'
              mr='2rem'
            />
            <Input
              label={t('longitude')}
              value={longitude}
              onChange={setLongitude}
              maxWidth='9rem'
              mr='2rem'
            />
            <Button
              text={t('search_for_location')}
              onClick={searchForLocation}
              disabled={!streetAddress || !postalCode || !city}
              variant='secondary'
              width='11rem'
              mt='1.75rem'
            />
          </Box>
          {renderMap()}
        </Box>
        <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
          {t('instructions_before_event')}
        </Typography>
        <Box sx={styles.formContainer}>
          <TextEditor
            label={t('instruction_text')}
            value={instructionsBeforeEvent}
            onChange={setInstructionsBeforeEvent}
          />
        </Box>
        <Typography variant='h2' mb='1.5rem' mt='2.5rem'>
          {t('instructions_during_event')}
        </Typography>
        <Box sx={styles.formContainer}>
          <TextEditor
            label={t('instruction_text')}
            value={instructionsDuringEvent}
            onChange={setInstructionsDuringEvent}
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('save')}
            onClick={save}
            backgroundColor={Colors.brandPrimary}
            width='100%'
            mb='0.5rem'
          />
        </Box>
        <Box sx={{ ...styles.actionsContainer, alignItems: 'flex-start' }}>
          <Typography variant='body1' fontSize='0.875rem' mb='1.125rem'>
            {t('event_visibility_info')}
          </Typography>
          <Checkbox
            label={t('visible_to_all_users')}
            checked={!hiddenInApp}
            onChange={toggleHiddenInApp}
            mb='0.5rem'
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateEvent)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '10rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '47rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: '2rem',
    backgroundColor: Colors.brandSecondaryDarker,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mt: '2.5rem',
    mb: '1rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  },
  separator: {
    marginTop: '1.625rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: Colors.white
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.text,
    fontSize: '1rem',
    fontWeight: 600
  },
  optionsContainer: {
    width: '20.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: Colors.black20,
    borderRadius: '0.625rem',
    p: '0.825rem 1.125rem',
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
  link: {
    textDecoration: 'underline',
    alignSelf: 'center',
    ':hover': {
      opacity: 0.8,
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1rem',
    mb: '1rem',
  },
  infoButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1rem 1.25rem',
    mb: '1rem',
    ':hover': {
      backgroundColor: Colors.cardBackground70,
    },
  },
  photo: {
    width: '100%',
    height: '10rem',
    objectFit: 'cover',
    borderRadius: '0.625rem',
    mb: '1rem',
  },
  activeDot: {
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    backgroundColor: '#4DB974',
    marginLeft: '0.5rem',
  },
  inactiveDot: {
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    backgroundColor: '#FAC37F',
    marginLeft: '0.5rem',
  },
} as const
