import { DateTime } from "luxon"

const isValidTime = (time: any) => {
  if (!time) return false
  if (time.length !== 5) return false
  const parts = time.split(':')
  if (parts.length !== 2 || parts[0].length !== 2) return false
  const hh = parseInt(parts[0], 10)
  const mm = parseInt(parts[1], 10)
  if (hh < 0 || hh > 23) return false
  if (mm < 0 || mm > 59) return false
  return true
}

const isValidDate = (date: any) => {
  if (!date) return false
  if (date.length !== 10) return false
  const parts = date.split('-')
  if (parts.length !== 3) return false

  const year = parts[0]
  const month = parts[1]
  const day = parts[2]

  if (year.length !== 4) return false
  if (month.length !== 2) return false
  if (day.length !== 2) return false

  return true
}

export const validateNotificationInput = (message: any) => {
  const {
    type,
    titleEn,
    contentEn,
    htmlEn,
    publishMode,
    publishedAtTime,
    publishedAtDate
  } = message

  if (!type) {
    return false
  }

  const enOk = titleEn && (contentEn || htmlEn)

  if (!enOk) {
    return false
  }
  if (!publishMode) {
    return false
  }

  if (publishMode === 'timed') {
    // Validate date and time
    if (!publishedAtTime || !publishedAtDate) return false
    if (publishedAtDate.length !== 10) return false
    if (publishedAtTime.length !== 5) return false
    if (publishedAtDate.split('-').length !== 3) return false
    if (publishedAtTime.split(':').length !== 2) return false
  }

  // All seems to be ok
  return true
}
