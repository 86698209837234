import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class ThrowStore {
  rootStore

  throws: any = []
  discThrow: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateThrows = (throws: any) => { this.throws = throws }
  updateThrow = (discThrow: any) => { this.discThrow = discThrow }
  updateLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.updateThrows([])
    this.updateThrow(null)
    this.updateLoading(false)
  }

  async getThrows(params?: any) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getThrows(params)
      if (response.ok) {
        const throws = response.data?.items || []
        this.updateThrows(throws)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getDiscThrows(discId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getDiscThrows(discId)
      if (response.ok) {
        const throws = response.data?.items || []
        const disc = response.data?.disc || {}
        if (disc) {
          this.rootStore.discStore.updateDisc(disc)
        }
        this.updateThrows(throws)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getThrow(throwId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getThrow(throwId)
      if (response.ok) {
        const throwData = response.data || null
        this.updateThrow(throwData)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveThrow(throwId: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveThrow(throwId)
      if (response.ok) {
        const discThrow = response.data || null
        this.updateThrow(discThrow)
        // this.getDiscThrows(discId)
        showMessage(i18n.t('throw_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('throw_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
