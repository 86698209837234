import { makeAutoObservable } from 'mobx'
import { DateTime } from 'luxon'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class AdminUserStore {
  rootStore

  users: any = []
  totalUsers = 0
  userInvites: any = []
  user: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateUsers = (users: any) => { this.users = users }
  updateTotalUsers = (total: any) => { this.totalUsers = total }
  updateUserInvites = (userInvites: any) => { this.userInvites = userInvites }
  updateUser = (user: any) => { this.user = user }
  updateLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.updateUsers([])
    this.updateTotalUsers(null)
    this.updateUser(null)
    this.updateLoading(false)
  }

  async getAdminUsers() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getAdminUsers()
      if (response.ok) {
        const users = response.data?.items || []
        const total = response.data?.total || 0
        this.updateUsers(users)
        this.updateTotalUsers(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getAdminUser(userId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.getAdminUser(userId)
      if (response.ok) {
        const user = response.data ?? null
        this.updateUser(user)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async editAdminUser(userId: number, payload: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateAdminUser(userId, payload)
      if (response.ok) {
        const user = response.data
        this.updateUser(user)
        showMessage(i18n.t('user_updated'))
      } else {
        showMessage(i18n.t('user_update_failed'), 'error')
      }
    } catch (e) {

    }
    this.updateLoading(false)
  }

  async updateAdminUserProfilePhoto(userId: number, file: any) {
    this.updateLoading(true)
    try {
      const response: any = await Api.updateAdminUserProfilePhoto(userId, file)
      if (response?.ok) {
        const user = response?.data || null
        this.updateUser(user)
        showMessage(i18n.t('user_updated'))
      } else {
        showMessage(i18n.t('user_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveAdminUserProfilePhoto(userId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.updateAdminUser(userId, { photo: null })
      if (response?.ok) {
        const user = response?.data || null
        this.updateUser(user)
        showMessage(i18n.t('user_updated'))
      } else {
        showMessage(i18n.t('user_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createUser(body: any, callback: any) {
    this.updateLoading(true)
    try {
      const response: any = await Api.createAdminUser(body)
      if (response?.ok) {
        this.getAdminUsers()
        showMessage(i18n.t('user_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveAdminUser(userId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveAdminUser(userId)
      if (response.ok) {
        const user = response.data
        this.updateUser(user)
        showMessage(i18n.t('user_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async activateAdminUser(userId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.activateAdminUser(userId)
      if (response.ok) {
        const user = response.data
        this.updateUser(user)
        showMessage(i18n.t('user_activated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_activate_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
