import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import SquareButton from '../../../Components/Common/SquareButton'
import EditIcon from '../../../Assets/Icons/edit.svg'
import TrashIcon from '../../../Assets/Icons/trash.svg'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { Colors } from '../../../Utils/theme'

const Notifications = (props: any) => {
  const { sessionStore, notificationStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { language } = sessionStore
  const { loading } = notificationStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [archiveRow, setArchiveRow] = useState<any>(null)

  const clearSearchKey = () => setSearchKey('')

  const handleDelete = () => {
    if (archiveRow) {
      notificationStore.archiveNotification(archiveRow.id)
      setArchiveRow(null)
    }
  }

  useEffect(() => {
    notificationStore.getNotifications()
  }, [])

  const toAdminUser = (id: number) => navigate(`/users/admin/${id}`)
  const toNotification = (notification: any) => navigate(`/notifications/${notification.id}`)
  const toCreateNotification = () => navigate('/notifications/create')

  const formatDateTime = (date: string) => {
    const dayPart = DateTime.fromISO(date).toFormat('dd.MM.yyyy')
    const timePart = DateTime.fromISO(date).toFormat('HH.mm')
    return `${dayPart} ${t('at')} ${timePart}`
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'title',
      headerName: t('title'),
      flex: 1,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.titleFi || params.row.titleEn || params.row.titleSv}
          onClick={() => toNotification(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'archivedAt',
      headerName: t('state'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        const now = DateTime.now()
        let text = params?.row?.archivedAt ?
          t('archived') :
          params.row?.sentAt && DateTime.fromISO(params.row.sentAt) < now ?
          t('sent') :
          t('not_sent')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'sentAt',
      headerName: t('sent_at_time'),
      flex: 1,
      maxWidth: 260,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.sentAt) {
          return formatDateTime(params.row.sentAt)
        }
        return formatDateTime(params.row.publishedAt)
      }
    },
    {
      field: 'creatorAdminUser',
      headerName: t('sender'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params.row.creatorAdminUser) return '-'
        return (
          <Button
            sx={styles.name}
            text={`${params.row.creatorAdminUser?.firstName} ${params.row.creatorAdminUser?.lastName}`.trim()}
            onClick={() => toAdminUser(params.row.creatorAdminUser?.id)}
            variant='text'
          />
        )
      }
    }
  ], [language])

  const rows = useMemo(() => {
    let notifications = notificationStore.notifications
    if (searchKey) {
      notifications = notifications.filter((notification: any) => 
        (`${notification.titleFi} ${notification.titleEn} ${notification.titleSv}`)
        .toLowerCase()
        .includes(searchKey.toLowerCase())
      )
    }
    return notifications
  }, [notificationStore.notifications, searchKey])

  const renderConfirmDelete = () => {
    if (archiveRow) {
      return (
        <ConfirmationDialog
          title={t('delete_notification')}
          content={t('delete_notification_confirmation')}
          onClose={() => setArchiveRow(null)}
          cancelText={t('cancel')}
          onAction={handleDelete}
          actionText={t('archive')}
          delete
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onAddClick={toCreateNotification}
      />
      <Table rows={rows} columns={columns} loading={loading} />
      {renderConfirmDelete()}
    </Box>
  )
}

export default observer(Notifications)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem'
  },
} as const
