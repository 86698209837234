import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import ArrowIcon from '../../Assets/Icons/dropdown-arrow.svg'
import { Colors } from '../../Utils/theme'

const ListBoxPadding = 8

// Context to pass additional props to the outer element
const OuterElementContext = React.createContext<Partial<React.HTMLAttributes<HTMLElement>>>({})

const OuterElementType = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function OuterElementType(props, ref) {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

// Function to render each row in the virtualized list
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const option = data[index]
  
  return React.cloneElement(option, {
    style: {
      ...style,
      top: (style.top as number) + ListBoxPadding,
    },
  })
}

// Custom ListboxComponent using react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props

  const itemData = React.Children.toArray(children) as React.ReactElement[]
  const itemCount = itemData.length
  const itemSize = 40

  const height = Math.min(8, itemCount) * itemSize + 2 * ListBoxPadding

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={height}
          width='100%'
          itemSize={itemSize}
          itemCount={itemCount}
          outerElementType={OuterElementType}
          innerElementType='ul'
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

interface OptionType {
  value: any
  label: string
}

interface SelectProps {
  id?: string
  value: any
  label?: string
  placeholder?: string
  options: OptionType[]
  onChange: (value: any) => void
  sx?: object
  width?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  ml?: string | number
  mr?: string | number
  mb?: string | number
  mt?: string | number
  disabled?: boolean
  getOptionLabel?: (option: any) => string
  isOptionEqualToValue?: (option: any, value: any) => boolean
}

export default function VirtualizedSelect(props: SelectProps) {
  const { id, value, label, placeholder, options, onChange } = props

  const { t } = useTranslation()

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const getLabel = (option: any) => options?.find((o) => o.value === option)?.label ?? ''
  const isOptionEqualToValue = (option: any, value: any) => option?.value === value

  const filterOptions = createFilterOptions({ stringify: (option: any) => option?.label })

  const handleChange = (event: any, newValue: OptionType | null) => {
    onChange(newValue?.value)
  }

  const renderArrowIcon = () => <Box component='img' src={ArrowIcon} alt='icon' />

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel sx={styles.label} htmlFor={id} disableAnimation>
          {label}
        </InputLabel>
      )
    }
    return null
  }

  const renderInput = (params: any) => (
    <TextField
      {...params}
      name='noAutoFill'
      placeholder={placeholder || (!props?.disabled ? t('select') : '')}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'off',
        sx: styles.input,
      }}
    />
  )

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: OptionType) => (
    <Box component='li' sx={styles.option} {...props}>
      {option.label}
    </Box>
  )

  return (
    <Box sx={getContainerStyle()}>
      {renderLabel()}
      <Autocomplete
        id={id}
        value={options.find((opt) => opt.value === value) || null}
        onChange={handleChange}
        options={options}
        getOptionLabel={props?.getOptionLabel || ((option) => option.label)}
        isOptionEqualToValue={props?.isOptionEqualToValue || isOptionEqualToValue}
        renderOption={renderOption}
        renderInput={renderInput}
        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        sx={styles.container}
        disabled={props?.disabled}
        popupIcon={!props?.disabled ? renderArrowIcon() : null}
        openText={t('open')}
        closeText={t('close')}
        clearText={t('clear')}
        noOptionsText={t('no_options')}
        filterOptions={filterOptions}
        autoHighlight
      />
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    '& .MuiAutocomplete-inputRoot': {
      p: '0rem 0.5rem',
      paddingLeft: '0.875rem',
      height: '3.125rem',
      borderRadius: '0.625rem',
      borderWidth: 0,
      backgroundColor: Colors.brandSecondary,
      '&:hover': {
        borderWidth: 0,
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
        },
      },
      '& .MuiAutocomplete-popupIndicator': {
        padding: '0.5rem',
      },
    },
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.white,
    fontSize: '1rem',
    fontWeight: 600,
  },
  input: {
    fontSize: '1.125rem',
    color: Colors.white,
    borderRadius: '0.625rem',
    borderWidth: 0,
    '&::placeholder': {
      color: Colors.white,
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
  },
  option: {
    fontSize: '1rem',
    color: Colors.white,
    backgroundColor: Colors.brandSecondary,
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
  },
} as const
