import { t } from 'i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Button } from '..'
import { Colors } from '../../Utils/theme'

export default function ConfirmationDialog(props: any) {
  const { title, content, onClose, cancelText, onAction, actionText, loading } = props

  return (
    <Dialog sx={styles.dialog} onClose={onClose} open>
      <DialogTitle variant='h2' sx={styles.title}>{title}?</DialogTitle>
      <DialogContent sx={styles.content}>
        <DialogContentText variant='body1' sx={styles.contentText}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={cancelText || t('cancel')}
          onClick={onClose}
          width='12rem'
          variant='text'
        />
        <Button
          sx={styles.button}
          text={actionText || t('save')}
          onClick={onAction}
          loading={loading}
          disabled={loading}
          width='12rem'
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      backgroundColor: Colors.brandSecondaryDarker,
      minWidth: {
        sm: '31rem',
        xs: '28rem',
      },
      width: {
        sm: '31rem',
        xs: '28rem',
      },
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  icon: {
    height: '4rem'
  },
  title: {
    color: Colors.text,
    paddingLeft: '1rem',
    alignSelf: 'center',
    textAlign: 'center'
  },
  content: {
    alignSelf: 'center',
    marginBottom: '0.5rem'
  },
  contentText: {
    fontSize: '1.125rem',
    color: Colors.text,
    textAlign: 'center'
  },
  outlinedButton: {
    color: Colors.text,
    fontWeight: 700,
    backgroundColor: Colors.transparent,
    ':hover': {
      backgroundColor: Colors.black20
    }
  },
  button: {
    backgroundColor: Colors.brandPrimary,
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: 0,
    marginTop: '0.5rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
