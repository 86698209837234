import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import LeftArrowIcon from '../../Assets/Icons/left-arrow.svg'
import RightArrowIcon from '../../Assets/Icons/right-arrow.svg'
import { Colors } from '../../Utils/theme'

const IconMappings: any = {
  arrowLeft: LeftArrowIcon,
  arrowRight: RightArrowIcon,
}

const IconButton = React.forwardRef((props: any, ref: any) => {
  const { sx, onClick, loading } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.button }
    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props?.mr) buttonStyles.mr = props.mr
    if (props?.ml) buttonStyles.ml = props.ml
    if (props?.transparentBg) {
      buttonStyles.backgroundColor = 'transparent'
      buttonStyles.color = Colors.white
      buttonStyles[':hover'] = {
        boxShadow: 0,
        backgroundColor: Colors.white10,
        borderRadius: '0.625rem'
      }
    }
    return buttonStyles
  }

  const getIconStyle = () => {
    const iconStyles: any = { ...styles.icon }
    if (props?.iconSize) {
      iconStyles.height = props.iconSize
      iconStyles.width = 'auto'
    }
    return iconStyles
  }

  return (
    <ButtonBase
      sx={getStyles()}
      onClick={onClick}
      disabled={loading || props?.disabled}
      ref={ref ?? null}
      aria-describedby={props?.ariaDescribedBy ?? undefined}
    >
      <Box component='img' src={IconMappings[props.icon]} sx={getIconStyle()} />
    </ButtonBase>
  )
})

export default IconButton

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    minWidth: 0,
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: '0.625rem',
    backgroundColor: Colors.white10,
    // backgroundColor: Colors.brandSecondaryDarker,
    ':hover': {
      backgroundColor: Colors.white20,
      boxShadow: 0
    },
    ':disabled': {
      opacity: 0.5
    }
  },
  icon: {
    height: '1.375rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
  }
} as const
