import { useEffect, useState } from 'react'
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import SquareButton from './SquareButton'
import styled from '@emotion/styled'
import OrderedListIcon from '../../Assets/Icons/ol.svg'
import UnorderedListIcon from '../../Assets/Icons/ul.svg'
import AlignLeftIcon from '../../Assets/Icons/align-left.svg'
import AlignCenterIcon from '../../Assets/Icons/align-center.svg'
import AlignRightIcon from '../../Assets/Icons/align-right.svg'
import { Bold, Italic, Underline as UnderlineI, Strikethrough } from 'emotion-icons/foundation'
import { Heading } from 'emotion-icons/boxicons-regular'
import { Colors } from '../../Utils/theme'

const BoldIcon = styled(Bold)`
  color: ${Colors.white};
  height: 1.875rem;
  width: auto;
`

const ItalicIcon = styled(Italic)`
  color: ${Colors.white};
  height: 1.875rem;
  width: auto;
`

const UnderlineIcon = styled(UnderlineI)`
  color: ${Colors.white};
  height: 1.875rem;
  width: auto;
`

const StrikeIcon = styled(Strikethrough)`
  color: ${Colors.white};
  height: 1.875rem;
  width: auto;
`

const H2Icon = styled(Heading)`
  color: ${Colors.white};
  font-weight: 700;
  height: 1.75rem;
  width: auto;
`

const MenuBar = (props: any) => {
  const { editor } = useCurrentEditor()

  useEffect(() => {
    if (editor && props?.content) {
      editor.commands.setContent(props.content)
    }
  }, [editor, props.content])

  if (!editor) {
    return null
  }

  const toggleBold = () => editor.chain().focus().toggleBold().run()
  const toggleItalic = () => editor.chain().focus().toggleItalic().run()
  const toggleUnderline = () => editor.chain().focus().toggleUnderline().run()
  const toggleStrike = () => editor.chain().focus().toggleStrike().run()
  const toggleHeading = (level: any) => editor.chain().focus().toggleHeading({ level }).run()
  const toggleBulletList = () => editor.chain().focus().toggleBulletList().run()
  const toggleOrderedList = () => editor.chain().focus().toggleOrderedList().run()
  const toggleAlignLeft = () => editor.chain().focus().setTextAlign('left').run()
  const toggleAlignCenter = () => editor.chain().focus().setTextAlign('center').run()
  const toggleAlignRight = () => editor.chain().focus().setTextAlign('right').run()

  const isBoldActive = editor.isActive('bold')
  const isItalicActive = editor.isActive('italic')
  const isUnderlineActive = editor.isActive('underline')
  const isStrikeActive = editor.isActive('strike')
  const isHeading2Active = editor.isActive('heading', { level: 2 })
  const isBulletListActive = editor.isActive('bulletList')
  const isOrderedListActive = editor.isActive('orderedList')
  const isAlignLeftActive = editor.isActive({ textAlign: 'left' })
  const isAlignCenterActive = editor.isActive({ textAlign: 'center' })
  const isAlignRightActive = editor.isActive({ textAlign: 'right' })

  return (
    <Box sx={styles.toolbar}>
      <SquareButton
        iconComponent={H2Icon}
        onClick={() => toggleHeading(2)}
        backgroundColor={isHeading2Active ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        mr='0.5rem'
      />
      <SquareButton
        iconComponent={BoldIcon}
        onClick={toggleBold}
        backgroundColor={isBoldActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        mr='0.25rem'
      />
      <SquareButton
        iconComponent={ItalicIcon}
        onClick={toggleItalic}
        backgroundColor={isItalicActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        mr='0.25rem'
      />
      <SquareButton
        iconComponent={UnderlineIcon}
        onClick={toggleUnderline}
        backgroundColor={isUnderlineActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        mr='0.25rem'
      />
      <SquareButton
        iconComponent={StrikeIcon}
        onClick={toggleStrike}
        backgroundColor={isStrikeActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        mr='0.5rem'
      />
      <SquareButton
        icon={OrderedListIcon}
        onClick={toggleOrderedList}
        backgroundColor={isOrderedListActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        iconSize='1.25rem'
        mr='0.5rem'
      />
      <SquareButton
        icon={UnorderedListIcon}
        onClick={toggleBulletList}
        backgroundColor={isBulletListActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        iconSize='1.125rem'
        mr='0.75rem'
      />
      <SquareButton
        icon={AlignLeftIcon}
        onClick={toggleAlignLeft}
        backgroundColor={isAlignLeftActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        iconSize='1.25rem'
        mr='0.75rem'
      />
      <SquareButton
        icon={AlignCenterIcon}
        onClick={toggleAlignCenter}
        backgroundColor={isAlignCenterActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        iconSize='1.25rem'
        mr='0.5rem'
      />
      <SquareButton
        icon={AlignRightIcon}
        onClick={toggleAlignRight}
        backgroundColor={isAlignRightActive ? Colors.white10 : Colors.transparent}
        size='2.5rem'
        iconSize='1.25rem'
      />
    </Box>
  )
}

const extensions = [
  Underline.configure({}),
  TextAlign.configure({
    types: ['heading', 'paragraph']
  }),
  Link.configure({}),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false
    },
  }),

]

export default function TextEditor(props?: any) {
  const [content, setContent] = useState('')

  useEffect(() => {
    if (props?.value) {
      setContent(props.value)
    }
  }, [props?.value])

  const onChange = (value: any) => {
    const html = value?.editor?.getHTML()
    if (html !== content) {
      props?.onChange(html)
    }
  }

  const renderLabel = () => {
    if (props?.label) {
      return (
        <InputLabel sx={styles.label} disableAnimation>
          {props?.label}
        </InputLabel>
      )
    }
    return null
  }

  return (
    <>
      {renderLabel()}
      <EditorProvider
        slotBefore={<MenuBar content={content} />}
        extensions={extensions}
        content={content}
        onBlur={onChange}
      />
    </>
  )
}


const styles = {
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.text,
    fontSize: '1rem',
    fontWeight: 600
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.brandPrimary,
    borderTopLeftRadius: '0.6125rem',
    borderTopRightRadius: '0.6125rem',
    // border: `1px solid ${Colors.black20}`,
    p: '0.375rem 1rem'
  }
} as const