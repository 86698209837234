import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import AdminUsers from './Users/AdminUsers'
import AdminUser from './Users/AdminUser'
import CreateAdminUser from './Users/CreateAdminUser'
import MobileUsers from './Users/MobileUsers'
import MobileUser from './Users/MobileUser'
import Discs from './Discs/Discs'
import Disc from './Discs/Disc'
import DiscThrows from './Throws/DiscThrows'
import Throw from './Throws/Throw'
import Throws from './Throws/Throws'
import Notifications from './Notifications/Notifications'
import Notification from './Notifications/Notification'
import CreateNotification from './Notifications/CreateNotification'
import Events from './Events/Events'
import Event from './Events/Event'
import CreateEvent from './Events/CreateEvent'
import EventThrows from './Events/EventThrows'
import Profile from './Profile/Profile'
import Support from './Support/Support'

type ProtectedRouteProps = {
  isAllowed: boolean
  redirectPath?: string
}

type AuthenticatedRouterProps = {
  isAdmin: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = '/events',
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default function Authenticated({ isAdmin }: AuthenticatedRouterProps) {
  return (
    <AppLayout>
      <Routes>
        {/* <Route path='/dash' element={<Dashboard />} /> */}
        <Route path='/users/admin' element={<AdminUsers />} />
        <Route path='/users/admin/create' element={<CreateAdminUser />} />
        <Route path='/users/admin/:userId' element={<AdminUser />} />
        <Route path='/users/mobile' element={<MobileUsers />} />
        <Route path='/users/mobile/:userId' element={<MobileUser />} />
        <Route path='/discs' element={<Discs />} />
        <Route path='/discs/:discId' element={<Disc />} />
        <Route path='/discs/:discId/throws' element={<DiscThrows />} />
        <Route path='/throws' element={<Throws />} />
        <Route path='/throws/:throwId' element={<Throw />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/notifications/create' element={<CreateNotification />} />
        <Route path='/notifications/:notificationId' element={<Notification />} />
        <Route path='/events' element={<Events />} />
        <Route path='/events/create' element={<CreateEvent />} />
        <Route path='/events/:eventUuid' element={<Event />} />
        <Route path='/events/:eventUuid/throws' element={<EventThrows />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/support' element={<Support />} />
        <Route path='*' element={<Navigate to='/events' />} />
      </Routes>
    </AppLayout>
  )
}