import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import upperFirst from 'lodash/upperFirst'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { DateTime } from 'luxon'
import {
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Event } from '../../../Types/events'
import { UUID } from '../../../Types/common'
import { showMessage } from '../../../Utils/message'
import { Typography } from '@mui/material'

const EventThrows = () => {
  const { eventStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams()

  const { loading, event } = eventStore

  const [deleteThrowId, setDeleteThrowId] = useState<number | null>(null)

  const openActionConfirmation = (id: number) => setDeleteThrowId(id)
  const closeActionConfirmation = () => setDeleteThrowId(null)
  
  useEffect(() => {
    const eventUuid = params?.eventUuid ?? null
    if (eventUuid) {
      eventStore.getEventThrows(eventUuid)
      const interval = setInterval(() => {
        eventStore.getEventThrows(eventUuid)
      }, 15000)
      return () => clearInterval(interval)
    }
  }, [eventStore, params?.eventUuid])

  // Table state
  const [searchKey, setSearchKey] = useState<keyof Event | ''>('')

  const clearSearchKey = () => setSearchKey('')
  
  const archiveThrow = () => {
    if (params?.eventUuid && deleteThrowId) {
      eventStore.archiveEventThrow(
        params.eventUuid,
        deleteThrowId,
        closeActionConfirmation
      )
    }
  }

  const columns: GridColDef<Event>[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('thrower_name'),
        mobileHeaderName: t('name'),
        flex: 1,
        maxWidth: 300,
        mobileMaxWidth: {
          sm: 200,
          xs: 115,
        },
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueGetter: (params: GridRenderCellParams<any>) => {
          if (params?.row?.mobileUser) {
            return `${params?.row?.mobileUser?.firstName} ${params?.row?.mobileUser?.lastName}`?.trim()
          }
          if (params?.row?.throwerName) {
            return params?.row?.throwerName
          }
          return '-'
        }
      },
      {
        field: 'startsAt',
        headerName: t('email'),
        flex: 1,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueGetter: (params: GridRenderCellParams<any>) => {
          if (params?.row?.mobileUser) {
            return `${params?.row?.mobileUser?.email}`?.trim()
          }
          return '-'
        }
      },
      {
        field: 'createdAt',
        headerName: t('skill_level'),
        flex: 1,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueGetter: (params: GridRenderCellParams<any>) => {
          if (params?.row?.mobileUser) {
            const skillLevel = params?.row?.mobileUser?.skillLevel || null
            if (skillLevel) {
              return t(skillLevel)
            }
          }
          return '-'
        }
      },
      {
        field: 'timestamp',
        headerName: t('timestamp'),
        headerClassName: 'gameproofer-table--header',
        flex: 1,
        maxWidth: 290,
        mobileMaxWidth: {
          sm: 200,
          xs: '100%'
        },
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          const dayPart = DateTime.fromMillis(Number(params.value)).toFormat('dd.MM.yyyy')
          const timePart = DateTime.fromMillis(Number(params.value)).toFormat('HH.mm.ss')
          return `${dayPart} ${t('at')} ${timePart}`
        }
      },
      /*
      {
        field: 'createdAt',
        headerName: t('thrower_status'),
        flex: 1,
        maxWidth: 260,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          let text = t('inactive')
          if (event?.activeThrowerId && params?.row?.mobileUser) {
            text = event?.activeThrowerId === params?.row?.mobileUser?.id ? t('active') : t('inactive')
          }
          if (event?.customActiveThrowerName && params?.row?.throwerName) {
            text = event?.customActiveThrowerName === params?.row?.throwerName ? t('active') : t('inactive')
          }
          return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
        }
      },
      */
      {
        field: 'updatedAt',
        headerName: t('actions'),
        flex: 1,
        maxWidth: 200,
        mobileMaxWidth: {
          sm: 150,
          xs: 90,
        },
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        sortable: false,
        exportable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={styles.actions}>
              <ButtonBase onClick={() => openActionConfirmation(params?.row?.id)}>
                <Chip
                  label={t('delete')}
                  variant='outlined'
                  size='small'
                  sx={styles.buttonChip}
                />
              </ButtonBase>
            </Box>
          )
        }
      }
    ],
    [t],
  )

  const getRowId = (item: any) => `${item.id}`

  const rows = useMemo(() => {
    let throws = eventStore.throws

    const searchFields: any[] = [
      'mobileUser.firstName',
      'mobileUser.lastName',
      'mobileUser.email',
      'throwerName'
    ]

    if (searchKey) {
      throws = throws.filter((field: any) => {
        for (const key of searchFields) {
          const value = field?.[key] ?? field?.[key?.split('.')[0]]?.[key?.split('.')[1]] ?? ''
          if (typeof value === 'string' && value.toLowerCase().includes(searchKey.toLowerCase())) {
            return true
          }
        }
        return false
      })
    }
    return throws
  }, [eventStore.throws, searchKey, loading])

  const renderConfirmationDialog = () => {
    if (deleteThrowId) {
      return (
        <ConfirmationDialog
          title={t('delete_throw')}
          content={t('delete_throw_confirmation')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archiveThrow}
          actionText={t('delete')}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Typography
        variant='h1'
        mb={{ lg: '1.5rem', xs: '1.125rem' }}
        alignSelf='flex-start'
        fontSize={{ lg: '2.75rem', xs: '2rem' }}
      >
        {event?.name || ''} - {upperFirst(t('throws'))}
      </Typography>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
      />
      <Table
        rows={rows}
        columns={columns}
        mobileColumns={{
          sm: ['id', 'startsAt', 'timestamp', 'updatedAt'],
          xs: ['id', 'timestamp', 'updatedAt']
        }}
        getRowId={getRowId}
        loading={loading}
      />
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(EventThrows)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: {
      lg: '2rem',
      xs: '1.5rem',
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      height: '100%'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
} as const
