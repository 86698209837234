import { useState, useCallback, useMemo, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
// import Lottie from 'lottie-react'
// import constructionAnimation from '../../Assets/Animations/construction.json'
import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Backdrop from '@mui/material/Backdrop'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ButtonBase from '@mui/material/ButtonBase'
import Sidebar from './Sidebar'
import Avatar from '@mui/material/Avatar'
import Slide from '@mui/material/Slide'
import ProfilePlaceholder from '../../Assets/Images/profile-placeholder.png'
import { Button } from '../../Components'
import Logo from '../../Assets/Icons/logo.svg'
import ArrowDown from '../../Assets/Icons/arrow-down.svg'
import { Menu as MaterialMenuIcon } from '@emotion-icons/material-rounded'
import Typography from '@mui/material/Typography'
import { AvailablePrimaryRoutes, AvailableSecondaryRoutes } from '../../Utils/constants'
import styled from '@emotion/styled'
import { Colors } from '../../Utils/theme'
import { pad } from 'lodash'

const MenuIcon = styled(MaterialMenuIcon)`
  color: ${Colors.heading};
  font-weight: 700;
`

function AppLayout(props: any) {
  const { t } = useTranslation()

  const currentLocation = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const scrollTrigger = useScrollTrigger({ threshold: 25 })

  const { sessionStore, discStore }: any = useStore()
  const { user, language } = sessionStore

  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(!open)

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null)
  const isProfileMenuOpen = Boolean(profileAnchorEl)
  const onProfileMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setProfileAnchorEl(event.currentTarget)
  }
  const onProfileMenuClose = (event: MouseEvent<any>) => {
    event.stopPropagation()
    setProfileAnchorEl(null)
  }

  const toProfile = () => navigate('/profile')
  const goBack = () => navigate(-1)
  const logout = () => sessionStore.logout()

  const ScreenTitle = useMemo(() => {
    if (currentLocation?.pathname) {
      const routes = [
        ...AvailablePrimaryRoutes(sessionStore.user?.role),
        ...AvailablePrimaryRoutes(sessionStore.user?.role)
          .map((item: any) => item?.children ?? [])
          .flat(),
        ...AvailableSecondaryRoutes(sessionStore.user?.role),
      ].flat()
      const title = routes.find((item: any) => item.url === currentLocation.pathname)?.title || ''
      if (title) {
        return t(title)
      }
    }
    if (currentLocation.pathname === '/profile') {
      return t('profile')
    }
    return ''
  }, [currentLocation, user, language])

  const getPrimaryNavigationItems = useCallback(() => {
    const role = sessionStore.user?.role ?? null
    return AvailablePrimaryRoutes(role)
  }, [sessionStore.user])

  const getSecondaryNavigationItems = useCallback(() => {
    if (sessionStore.user) {
      const role = sessionStore.user?.role ?? null
      return AvailableSecondaryRoutes(role)
    }
    return []
  }, [sessionStore.user])

  const renderScreenTitleOrAction = () => {
    if (!lgUp) {
      // return null
      return <Box component='img' src={Logo} alt='logo' sx={styles.logo} />
    }

    if (currentLocation.pathname?.endsWith('throws') && currentLocation.pathname?.includes('disc')) {
      const name = discStore?.disc?.name || ''
      return <Typography variant='h1'>{name}{name ? ' - ' : ''}{t('throws')}</Typography>
    }

    if (!ScreenTitle && currentLocation.pathname !== '/profile') {
      return <Button text={t('back_to_list')} onClick={goBack} icon='back' iconSx={styles.backButtonIcon} />
    }
    return <Typography variant='h1'>{ScreenTitle}</Typography>
  }

  const renderProfile = () => {
    if (lgUp) {
      return (
        <ButtonBase sx={styles.profileButton} onClick={toProfile}>
          <Avatar sx={styles.avatar} src={user?.photo || ProfilePlaceholder} alt='photo' />
          <Box sx={styles.profileColumn}>
            <Typography sx={styles.name} variant='body1'>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography sx={styles.role} variant='body2'>
              {t(user?.role)}
            </Typography>
          </Box>
          <ButtonBase onClick={onProfileMenuClick} sx={styles.menuButton}>
            <Box component='img' src={ArrowDown} sx={styles.arrowDown} />
          </ButtonBase>
          <Backdrop sx={styles.backdrop} open={isProfileMenuOpen} onClick={onProfileMenuClose}>
            <Menu
              anchorEl={profileAnchorEl}
              open={isProfileMenuOpen}
              onClose={onProfileMenuClose}
              sx={styles.profileMenu}
              // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={logout}>{t('logout')}</MenuItem>
            </Menu>
          </Backdrop>
        </ButtonBase>
      )
    }
    return (
      <ButtonBase sx={styles.button} onClick={toProfile}>
        <Avatar sx={styles.avatar} src={user?.photo || ProfilePlaceholder} alt='photo' />
      </ButtonBase>
    )
  }

  const renderMenu = () => {
    if (!lgUp) {
      return (
        <ButtonBase sx={styles.menuButton} onClick={toggleDrawer}>
          <MenuIcon size='2.5rem' />
        </ButtonBase>
      )
    }
    return null
  }

  // if (!user) {
  //   return null
  // }
  
  /*
  if (!lgUp && !currentLocation?.pathname?.includes('/throws')) {
    return (
      <Box sx={styles.underConstruction}>
        <Box component='img' src={Logo} sx={styles.underConstructionLogo} alt='logo' />
        <Lottie animationData={constructionAnimation} style={styles.underConstructionAnimation} />
      </Box>
    )
  }
  */

  return (
    <Box sx={styles.root}>
      <Box sx={styles.sidebarContainer}>
        <Sidebar
          open={open}
          toggleDrawer={toggleDrawer}
          logout={logout}
          primaryNavigationItems={getPrimaryNavigationItems()}
          secondaryNavigationItems={getSecondaryNavigationItems()}
          user={sessionStore.user}
          permanentDrawer={lgUp}
        />
      </Box>
      <Slide appear={false} direction='down' in={!scrollTrigger}>
        <AppBar sx={styles.appBar} elevation={0}>
          <Toolbar variant='dense' sx={styles.toolbar} disableGutters>
            {renderScreenTitleOrAction()}
            <Box display='flex' flexDirection='row'>
              <Box sx={styles.actions}>{renderProfile()}</Box>
              {renderMenu()}
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box component='main' sx={styles.content}>
        {props.children}
      </Box>
    </Box>
  )
}

export default observer(AppLayout)

const styles = {
  underConstruction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    backgroundColor: Colors.brandBlue,
  },
  underConstructionLogo: {
    width: {
      md: '35vw',
      sm: '50vw',
      xs: '65vw',
    },
    height: 'auto',
    margin: {
      xs: '3rem 0 3rem 0',
    },
  },
  underConstructionAnimation: {
    width: 'auto',
    height: '50vh',
  },
  root: {
    //display: 'flex',
    // flexDirection: 'column',
    height: '100vh',
    backgroundColor: Colors.transparent,
  },
  sidebarContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 996
  },
  appBar: {
    backgroundColor: {
      lg: Colors.transparent,
      xs: Colors.background
    },
    zIndex: 997,
    alignItems: {
      lg: 'flex-end',
      xs: 'center',
    },
    // borderBottom: `1px solid ${Colors.border}`,
    height: {
      lg: '5.5rem',
      xs: '4.5rem',
    },
    width: {
      // xxxl: 'calc(100% - 20rem)',
      // lg: 'calc(100% - 20rem)',
      lg: 'calc(100% - 18rem)',
      xs: '100%',
    },
    ml: {
      xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lg: '18rem',
      xs: 0,
    },
    pl: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem',
    },
    pr: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem',
    },
    boxShadow: {
      lg: 'none',
      xs: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
    }
  },
  contentContainer: {
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: {
      xs: '7rem',
    },
    height: 'auto',
  },
  actions: {},
  profileButton: {
    borderRadius: '0.625rem',
    p: '0.75rem 1rem',
    backgroundColor: Colors.brandPrimary,
    ':hover': {
      backgroundColor: Colors.brandPrimary90,
    },
  },
  button: {
    borderRadius: '50%',
    mr: {
      sm: '1.25rem',
      xs: '1.5rem',
    },
  },
  avatar: {
    width: '2.625rem',
    height: '2.625rem',
  },
  profileColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ml: '0.75rem',
    mr: '1rem',
  },
  name: {
    color: Colors.white,
    fontWeight: 700,
  },
  role: {
    color: Colors.white80,
    fontWeight: 400,
    mt: '-0.125rem'
  },
  menuButton: {
    height: '3rem',
    width: '3rem',
    backgroundColor: Colors.transparent,
    borderRadius: '0.625rem',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    width: {
      // xxxl: 'calc(100% - 20rem)',
      // lg: 'calc(100% - 20rem)',
      lg: 'calc(100% - 18rem)',
      xs: '100%',
    },
    ml: {
      // xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lg: '18rem',
      xs: 0,
    },
    pt: {
      lg: '6rem',
      xs: '4.5rem',
    },
    pl: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem',
    },
    pr: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem',
    },
  },
  backButtonIcon: {
    width: '1rem',
    height: '1rem',
  },
  arrowDown: {
    width: '1rem',
    height: '1rem',
    ml: '0.5rem',
  },
  backdrop: {
    backgroundColor: Colors.black50,
    zIndex: 1204,
  },
  profileMenu: {
    '& .MuiMenu-paper': {
      backgroundColor: Colors.brandPrimary,
      borderRadius: '0.625rem',
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      color: Colors.white,
      fontWeight: 600,
      fontSize: '1.125rem',
      padding: '1rem 1.5rem',
    },
  }
} as const
