import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import { CountryOptions, UserRoles } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'

const CreateAdminUser = (props: any) => {
  const { sessionStore, adminUserStore }: any = useStore()
  const { user } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')

  const create = () => {
    const callback = () => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setCountry('')
    }
    const user = {
      firstName,
      lastName,
      email,
      phone,
      country,
    }
    adminUserStore.createUser(user, callback)
  }
  const cancel = () => navigate(-1)

  const getRoleOptions = () => [{ value: UserRoles.Admin, label: t(UserRoles.Admin) }]

  const isSendDisabled = () => {
    if (!email || !email.includes('@')) return true
    return false
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mb='1rem'>{t('user_information')}</Typography>
         <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={setLastName}
            />
          </Box>
          <Box sx={styles.row}>
            <Input
              label={t('email')}
              value={email}
              onChange={setEmail}
              mr='2rem'
            />
            <Input
              label={t('phone')}
              value={phone}
              onChange={setPhone}
              maxWidth='14rem'
            />
          </Box>
          <Select
            label={t('country')}
            options={CountryOptions}
            value={country}
            onChange={setCountry}
            maxWidth='24rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h2' mt='2rem' mb='1rem'>{t('permissions')}</Typography>
        <Box sx={styles.formContainer}>
          <Select
            label={t('user_role')}
            options={getRoleOptions()}
            value={user?.role}
            mb='1.5rem'
            disabled
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mt='2rem' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_user')}
            onClick={create}
            backgroundColor={Colors.brandPrimary}
            width='100%'
            mb='1.25rem'
            disabled={isSendDisabled()}
          />
          <Button
            text={t('cancel')}
            onClick={cancel}
            variant='text'
            width='100%'
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateAdminUser)

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    paddingBottom: '10rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '47rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    mt: '1rem',
    mb: '2.5rem'
  },
  photo: {
    width: '12rem',
    height: '12rem',
    marginRight: '2rem'
  },
  photoActions: {
    height: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      lg: '2rem 2rem 0.5rem 2rem',
      xs: '1.5rem 1.5rem 0.5rem 1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: {
      lg: 'sticky',
      xs: 'relative',
    },
    top: {
      lg: '2rem'
    },
    ml: {
      lg: '2rem',
      xs: 0
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'auto'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      lg: '20rem',
      sm: '30rem',
      xs: '100%',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
} as const
