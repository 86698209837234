import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import { DateTime } from 'luxon'
import {
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Event } from '../../../Types/events'
import { UUID } from '../../../Types/common'
import { showMessage } from '../../../Utils/message'

const Events = () => {
  const { eventStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { loading } = eventStore

  // Table state
  const [searchKey, setSearchKey] = useState<keyof Event | ''>('')

  const clearSearchKey = () => setSearchKey('')

  useEffect(() => {
    eventStore.getEvents()
    eventStore.updateEvent(null)
  }, [eventStore])

  const toEvent = useCallback((eventUuid: UUID) => navigate(`/events/${eventUuid}`), [navigate])
  
  const copyToClipboard = (url: string) => () => {
    navigator.clipboard.writeText(url)
    showMessage(t('copied_to_clipboard'))
  }
  const toThrows = useCallback((eventUuid: UUID) => navigate(`/events/${eventUuid}/throws`), [navigate])

  const columns: GridColDef<Event>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('event'),
        flex: 1,
        minWidth: 240,
        mobileMinWidth: {
          sm: 220,
          xs: 160
        },
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Button
              sx={styles.name}
              text={params.row?.name}
              onClick={() => toEvent(params.row.uuid)}
              variant='text'
            />
          )
        }
      },
      {
        field: 'startsAt',
        headerName: t('status'),
        flex: 1,
        maxWidth: 180,
        mobileMaxWidth: {
          sm: 150,
          xs: 110
        },
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          const now = DateTime.now()
          let text = params?.row?.archivedAt ?
            t('archived') :
            params.row?.endsAt && DateTime.fromISO(params.row.endsAt) < now ?
            t('ended') :
            params?.row?.startsAt && DateTime.fromISO(params.row.startsAt) > now ?
            t('upcoming') :
            t('active')
          return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
        }
      },
      {
        field: 'hiddenInApp',
        headerName: t('visible_in_app'),
        flex: 1,
        maxWidth: 190,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          let text = params?.row?.hiddenInApp ? t('not_visible') : t('visible')
          return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
        }
      },
      {
        field: 'throwerCount',
        headerName: t('thrower_count'),
        flex: 1,
        maxWidth: 190,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => `${params?.value || 0} ${t('pcs')}`
      },
      {
        field: 'throwCount',
        headerName: t('result_count'),
        flex: 1,
        maxWidth: 190,
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => `${params?.value || 0} ${t('pcs')}`
      },
      {
        field: 'createdAt',
        headerName: t('created_at'),
        headerClassName: 'gameproofer-table--header',
        flex: 1,
        maxWidth: 240,
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          const dayPart = DateTime.fromISO(params.value).toFormat('dd.MM.yyyy')
          const timePart = DateTime.fromISO(params.value).toFormat('HH.mm')
          return `${dayPart} ${t('at')} ${timePart}`
        }
      },
      {
        field: 'publicLink',
        headerName: t('link'),
        flex: 1,
        maxWidth: 160,
        mobileMaxWidth: {
          sm: 110,
          xs: 80
        },
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        sortable: false,
        exportable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={styles.actions}>
              <ButtonBase onClick={copyToClipboard(params?.row?.publicLink)}>
                <Chip
                  label={t('copy')}
                  variant='outlined'
                  size='small'
                  sx={styles.buttonChip}
                />
              </ButtonBase>
            </Box>
          )
        }
      },
      {
        field: 'uuid',
        headerName: t('throws'),
        flex: 1,
        maxWidth: 160,
        mobileMaxWidth: {
          sm: 110,
          xs: 80
        },
        headerClassName: 'gameproofer-table--header',
        disableColumnMenu: true,
        sortable: false,
        exportable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={styles.actions}>
              <ButtonBase onClick={() => toThrows(params.row?.uuid)}>
                <Chip
                  label={t('view')}
                  variant='outlined'
                  size='small'
                  sx={styles.buttonChip}
                />
              </ButtonBase>
            </Box>
          )
        }
      }
    ],
    [t, toEvent],
  )

  const getRowId = (item: any) => `${item.id}`

  const rows = useMemo(() => {
    let events = eventStore.events

    const searchFields: (keyof Event)[] = ['name']

    if (searchKey) {
      events = events.filter((field) => {
        for (const key of searchFields) {
          const value = field[key]
          if (typeof value === 'string' && value.toLowerCase().includes(searchKey.toLowerCase())) {
            return true
          }
        }
        return false
      })
    }
    return events
  }, [eventStore.events, searchKey])

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onAddClick={() => toEvent('create')}
      />
      <Table
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        loading={loading}
        mobileColumns={{
          sm: ['name', 'startsAt', 'throwCount', 'publicLink', 'uuid'],
          xs: ['name', 'publicLink', 'uuid']
        }}
      />
    </Box>
  )
}

export default observer(Events)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 8rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
} as const
