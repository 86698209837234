import Lottie from 'lottie-react'
import emptyAnimation from '../../Assets/Animations/empty.json'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Colors } from '../../Utils/theme'

export default function NoRowsOverlay(props: any) {
  const { t } = useTranslation()
  return (
    <Box sx={styles.container}>
      <Lottie animationData={emptyAnimation} style={styles.animation} />
      <Typography variant='body2' sx={styles.info}>
        {props.noRowsMessage || t('no_results')}
      </Typography>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '8rem',
    marginBottom: '8rem'
  },
  animation: {
    width: 'auto',
    height: '10rem',
    marginTop: '-5rem'
  },
  info: {
    color: Colors.white,
    fontSize: '1.125rem'
  }
} as const
