import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Input from '../../../Components/Common/Input'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const AdminUsers = (props: any) => {
  const { sessionStore, adminUserStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading, users } = adminUserStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    adminUserStore.getAdminUsers()
  }, [])

  const toUser = (row: any) => navigate(`/users/admin/${row.id}`)
  const toMobileUser = (mobileUserId: string) => navigate(`/users/mobile/${mobileUserId}`)

  const toCreateUser = () => navigate(`/users/admin/create`)

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: t('user_name'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={`${params.row.firstName} ${params.row.lastName}`.trim()}
          onClick={() => toUser(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'role',
      headerName: t('user_role'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => t(params.value)
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      minWidth: 200,
      maxWidth: 500,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Chip label={params.row.email} variant='outlined' size='small' sx={styles.chip} />
      )
    },
    {
      field: 'archivedAt',
      headerName: t('status'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const status = params?.row?.archivedAt ?
          'archived' :
          params?.row?.status === 'inactive' ?
          'invite_sent' :
          'active'
        return <Chip label={t(status)} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'lastActiveAt',
      headerName: t('last_active_at'),
      flex: 1,
      maxWidth: 260,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          const datetime = DateTime.fromISO(params.value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    /*
    {
      field: 'uuid',
      headerName: t('app_account'),
      flex: 1,
      maxWidth: 160,
      mobileMaxWidth: {
        sm: 110,
        xs: 80
      },
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      sortable: false,
      exportable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={styles.actions}>
            <ButtonBase onClick={() => toMobileUser(params.row?.mobileUserId)}>
              <Chip
                label={t('open')}
                variant='outlined'
                size='small'
                sx={styles.buttonChip}
              />
            </ButtonBase>
          </Box>
        )
      }
    }
    */
  ], [language])

  const rows = useMemo(() => {
    let foundUsers = users
    if (searchKey) {
      foundUsers = users.filter((user: any) => `${user?.firstName} ${user?.lastName}`?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    return foundUsers
  }, [users, searchKey, filters])

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={toCreateUser}
      />
      <Table rows={rows} columns={columns} loading={loading} />
    </Box>
  )
}

export default observer(AdminUsers)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 8rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
} as const
