import { makeAutoObservable } from 'mobx'
import { DateTime } from 'luxon'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class MobileUserStore {
  rootStore

  users: any = []
  userOptions: any = []
  totalUsers = 0
  userInvites: any = []
  user: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateUsers = (users: any) => { this.users = users }
  updateUserOptions = (userOptions: any) => { this.userOptions = userOptions }
  updateTotalUsers = (total: any) => { this.totalUsers = total }
  updateUserInvites = (userInvites: any) => { this.userInvites = userInvites }
  updateUser = (user: any) => { this.user = user }
  updateLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.updateUsers([])
    this.updateTotalUsers(null)
    this.updateUser(null)
    this.updateLoading(false)
  }

  async getMobileUsers() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getMobileUsers()
      if (response.ok) {
        const users = response.data?.items || []
        const total = response.data?.total || 0
        this.updateUsers(users)
        this.updateTotalUsers(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getMobileUserOptions() {
    this.updateLoading(true)
    try {
      const response = await Api.getMobileUserOptions()
      if (response.ok) {
        const userOptions = response.data ?? []
        this.updateUserOptions(userOptions)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getMobileUser(userId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.getMobileUser(userId)
      if (response.ok) {
        const user = response.data ?? null
        this.updateUser(user)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async editMobileUser(userId: number, payload: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateMobileUser(userId, payload)
      if (response.ok) {
        const user = response.data
        this.updateUser(user)
        showMessage(i18n.t('user_updated'))
      } else {
        showMessage(i18n.t('user_update_failed'), 'error')
      }
    } catch (e) {

    }
    this.updateLoading(false)
  }

  async archiveMobileUser(userId: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveMobileUser(userId)
      if (response.ok) {
        const user = response?.data || null
        this.updateUser(user)
        this.getMobileUsers()
        showMessage(i18n.t('user_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async activateMobileUser(userId: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.activateMobileUser(userId)
      if (response.ok) {
        const user = response?.data || null
        this.updateUser(user)
        showMessage(i18n.t('user_activated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_activation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
