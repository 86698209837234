import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import ArrowIcon from '../../Assets/Icons/dropdown-arrow.svg'
import { Colors } from '../../Utils/theme'

export default function Select(props: any) {
  const { id, value, label, placeholder, options } = props

  const { t } = useTranslation()

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const getLabel = (option: any) => options?.find((o: any) => o.value === option)?.label ?? ''
  const isOptionEqualToValue = (option: any, value: any) => option?.value === value

  const filterOptions = createFilterOptions({ stringify: (option: any) => option?.label })

  const onChange = (event: any, newValue: any) => props.onChange(newValue?.value)

  const renderArrowIcon = () => <Box component='img' src={ArrowIcon} alt='icon' />

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel sx={styles.label} htmlFor={id} disableAnimation>
          {label}
        </InputLabel>
      )
    }
    return null
  }

  const renderInput = (params: any) => (
    <TextField
      {...params}
      name='noAutoFill'
      placeholder={placeholder || (!props?.disabled ? t('select') : '')}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'off',
        sx: styles.input
      }}
    />
  )

  const renderOption = (props: any, option: any) => {
    return (
      <Box component='li' sx={styles.option} {...props}>
        {option?.label}
      </Box>
    )
  }

  return (
    <Box sx={getContainerStyle()}>
      {renderLabel()}
      <Autocomplete
        id={id}
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={props?.getOptionLabel || getLabel}
        isOptionEqualToValue={props?.isOptionEqualToValue || isOptionEqualToValue}
        renderOption={renderOption}
        renderInput={renderInput}
        sx={styles.container}
        disabled={props?.disabled}
        popupIcon={!props?.disabled ? renderArrowIcon() : null}
        openText={t('open')}
        closeText={t('close')}
        clearText={t('clear')}
        noOptionsText={t('no_options')}
        filterOptions={filterOptions}
        // freeSolo
        // forcePopupIcon
        autoHighlight
      />
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    '& .MuiAutocomplete-inputRoot': {
      p: '0rem 0.5rem',
      paddingLeft: '0.875rem',
      height: '3.125rem',
      borderRadius: '0.625rem',
      borderWidth: 0,
      backgroundColor: Colors.brandSecondary,
      '&:hover': {
        borderWidth: 0
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0
        }
      },
      '& .MuiAutocomplete-popupIndicator': {
        padding: '0.5rem'
      }
    }
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.white,
    fontSize: '1rem',
    fontWeight: 600
  },
  input: {
    fontSize: '1.125rem',
    color: Colors.white,
    borderRadius: '0.625rem',
    borderWidth: 0,
    '&::placeholder': {
      color: Colors.white
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0
      }
    },
  },
  option: {
    fontSize: '1rem',
    color: Colors.white,
    backgroundColor: Colors.brandSecondary
  }
} as const
