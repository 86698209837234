import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const MobileUsers = (props: any) => {
  const { sessionStore, mobileUserStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const gridApiRef = useGridApiRef()

  const { language } = sessionStore
  const { loading, users } = mobileUserStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    mobileUserStore.getMobileUsers()
  }, [])

  const toUser = (row: any) => navigate(`/users/mobile/${row.id}`)

  const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef)

  const downloadCsv = () => {
    gridApiRef.current.exportDataAsCsv({
      getRowsToExport: getFilteredRows,
      allColumns: true,
      fileName: `mobile-users-${DateTime.now().toFormat('dd-MM-yyyy-HH-mm')}`,
      utf8WithBom: true
    })
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'firstName',
      headerName: t('user_name'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => `${params?.row?.firstName} ${params?.row?.lastName}`.trim(),
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={`${params.row.firstName} ${params.row.lastName}`.trim()}
          onClick={() => toUser(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'archivedAt',
      headerName: t('status'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        const text = params?.row?.archivedAt ? 'closed' : params?.row?.status === 'inactive' ? 'invite_sent' : 'active'
        return t(text)
      },
      renderCell: (params: GridRenderCellParams) => {
        const status = params?.row?.archivedAt ?
          'closed' :
          params?.row?.status === 'inactive' ?
          'invite_sent' :
          'active'
        return <Chip label={t(status)} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'createdAt',
      headerName: t('registered_at'),
      flex: 1,
      maxWidth: 260,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          const datetime = DateTime.fromISO(params.value)
          return datetime.toFormat('dd.MM.yyyy')
        }
        return '-'
      }
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      minWidth: 300,
      maxWidth: 600,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Chip label={params.row.email} variant='outlined' size='small' sx={styles.chip} />
      )
    },
    {
      field: 'skillLevel',
      headerName: t('skill_level'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params.value ? t(params.value) : '-'
    },
    {
      field: 'uuid',
      headerName: t('disc_count'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'gameproofer-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params?.row?.discs?.length,
      renderCell: (params: GridRenderCellParams) => {
        return <Chip label={`${params?.row?.discs?.length} ${t('pcs')}`} variant='outlined' size='small' sx={styles.chip} />
      }
    },
  ], [language])

  const rows = useMemo(() => {
    let foundUsers = users
    if (searchKey) {
      foundUsers = users.filter((user: any) => `${user?.firstName} ${user?.lastName} ${user?.email}`?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    return foundUsers
  }, [users, searchKey, filters])

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        searchPlaceholder={t('search_by_name_or_email')}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        csvDisabled={!rows.length}
        onCsvDownload={downloadCsv}
      />
      <Table
        rows={rows}
        columns={columns}
        loading={loading}
        apiRef={gridApiRef}
      />
    </Box>
  )
}

export default observer(MobileUsers)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 8rem)',
      xs: 'calc(100vh - 8.5rem)',
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
    },
  },
  chip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  buttonChip: {
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.darkBackground,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    transition: 'all 0.2s',
    '&:hover': {
      fontWeight: 600,
      backgroundColor: Colors.darkBackground80
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem',
  },
} as const
