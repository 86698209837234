import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoBox from '../../../Components/Common/InfoBox'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import TransferDiscOwnershipModal from '../../../Components/Common/TransferDiscOwnershipModal'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Disc = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { sessionStore, mobileUserStore, discStore }: any = useStore()
  const { loading, disc } = discStore

  const [name, setName] = useState('')
  const [originalCreatedAt, setOriginalCreatedAt] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [firmware, setFirmware] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')

  const openUnlockConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('unlock')
  }
  const openTransferOwnershipConfirmation = () => {
    setActionType('transfer_ownership')
    mobileUserStore.getMobileUserOptions()
  }
  const openSetLostOrFoundConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('set_lost_or_found')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
  }

  useEffect(() => {
    const discId = params?.discId ?? null
    if (discId) {
      discStore.getDisc(discId)
    }
  }, [])

  useEffect(() => {
    if (disc) {
      setName(disc?.archivedAt ? '' : disc?.name || '')
      // Original created at
      const originalDayPart = DateTime.fromISO(disc.originalCreatedAt).toFormat('dd.MM.yyyy')
      const originalTimePart = DateTime.fromISO(disc.originalCreatedAt).toFormat('HH.mm')
      const originalCreatedAt = `${originalDayPart} ${t('at')} ${originalTimePart}`
      setOriginalCreatedAt(originalCreatedAt)
      // Created at
      const dayPart = DateTime.fromISO(disc.createdAt).toFormat('dd.MM.yyyy')
      const timePart = DateTime.fromISO(disc.createdAt).toFormat('HH.mm')
      const createdAt = `${dayPart} ${t('at')} ${timePart}`
      setCreatedAt(createdAt)
      setFirmware(disc.firmware)
    }
  }, [disc])

  const toDiscThrows = () => navigate(`/discs/${params.discId}/throws`)
  const toMobileUser = () => navigate(`/users/mobile/${disc?.mobileUserId}`)

  const unlockDisc = () => {
    discStore.unlockDisc(
      Number(params.discId),
      closeActionConfirmation
    )
  }
  const transferOwnership = (userId: number) => {
    discStore.transferDiscOwnership(
      Number(params.discId),
      userId,
      closeActionConfirmation
    )
  }
  const setAsLost = () => {
    discStore.setAsLost(
      Number(params.discId),
      closeActionConfirmation
    )
  }
  const setAsFound = () => {
    discStore.setAsFound(
      Number(params.discId),
      closeActionConfirmation
    )
  }

  const renderFindMyDiscAction = () => {
    return (
      <Button
        text={disc?.status === 'lost' ? t('set_as_found') : t('set_as_lost')}
        onClick={openSetLostOrFoundConfirmation}
        width='100%'
        variant='secondary'
        mb={disc?.throwCount ? '1.25rem' : '0.5rem'}
      />
    )
  }

  const renderUnlockAction = () => {
    return (
      <Button
        text={t('unlock_disc')}
        onClick={openUnlockConfirmation}
        width='100%'
        variant='secondary'
        mb='1.25rem'
        disabled={disc?.archivedAt}
      />
    )
  }

  const renderTranferOwnershipAction = () => {
    return (
      <Button
        text={t('transfer_to_user')}
        onClick={openTransferOwnershipConfirmation}
        width='100%'
        variant='secondary'
        mb='1.25rem'
      />
    )
  }

  const renderThrowsAction = () => {
    if (disc?.throwCount) {
      return (
        <Button
          text={t('view_throws')}
          onClick={toDiscThrows}
          width='100%'
          variant='secondary'
          mb='0.5rem'
        />
      )
    }
    return null
  }

  const renderTransferOwnershipDialog = () => {
    if (actionType === 'transfer_ownership') {
      return (
        <TransferDiscOwnershipModal
          onClose={closeActionConfirmation}
          onSave={transferOwnership}
          userOptions={mobileUserStore.userOptions}
          loading={loading}
          archived={disc?.archivedAt}
        />
      )
    }
  }

  const renderConfirmationDialog = () => {
    if (showActionConfirmation) {
      if (actionType === 'unlock') {
        return (
          <ConfirmationDialog
            title={t('unlock_disc')}
            content={t('unlock_disc_info')}
            onClose={closeActionConfirmation}
            onAction={unlockDisc}
            actionText={t('unlock')}
            loading={loading}
          />
        )
      } else if (actionType === 'set_lost_or_found') {
        return (
          <ConfirmationDialog
            title={disc?.status === 'lost' ? t('set_as_found') : t('set_as_lost')}
            content={disc?.status === 'lost' ? t('set_as_found_info') : t('set_as_lost_info')}
            onClose={closeActionConfirmation}
            onAction={disc?.status === 'lost' ? setAsFound : setAsLost}
            actionText={t('confirm')}
            loading={loading}
          />
        )
      }
    }
    return null
  }

  if (!disc) return null

  console.log('Disc', disc)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mt='1rem' mb='1rem'>{t('general_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('disc_name')}
            value={name}
            onChange={() => {}}
            mb='1.5rem'
            disabled
          />
          <Box sx={styles.row}>
            <Input
              label={t('originally_created_at')}
              value={originalCreatedAt}
              onChange={() => {}}
              mr='2rem'
              disabled
            />
            <Input
              label={t('last_paired_at')}
              value={createdAt}
              onChange={() => {}}
              disabled
            />
          </Box>
          <Input
            label={t('current_firmware')}
            value={firmware}
            onChange={() => {}}
            width='calc(50% - 1rem)'
            mb='1.5rem'
            disabled
          />
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('user_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('user')}
            value={
              disc?.archivedAt ?
                null :
                `${disc?.firstName || ''} ${disc?.lastName || ''} ${disc?.email ? `(${disc.email})` : ''}`?.trim() || '-'}
            onChange={() => {}}
            toEntity={disc?.mobileUserId && !disc?.archivedAt ? toMobileUser : undefined}
            mb='1.5rem'
            disabled
          />
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('information_about')}</Typography>
        <Box sx={styles.formContainer}>
          <Box display='flex' flexDirection='row' mb='1.5rem'>
            <InfoBox
              label={t('latest_throw_at')}
              value={
                disc?.lastThrowAt ?
                  `${DateTime.fromISO(disc.lastThrowAt).toFormat('dd.MM.yyyy')} ${t('at')} ${DateTime.fromISO(disc.lastThrowAt).toFormat('HH.mm')}` :
                  '-'
              }
            />
            <InfoBox
              label={t('throws_count')}
              value={`${disc.throwCount} ${t('pcs')}`}
              mr='4rem'
            />
            <Box sx={{ width: '100%' }} />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mt='1rem' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          {renderUnlockAction()}
          {renderTranferOwnershipAction()}
          {renderFindMyDiscAction()}
          {renderThrowsAction()}
        </Box>
      </Box>
      {renderTransferOwnershipDialog()}
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(Disc)

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '47rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    mt: '1rem',
    mb: '2rem'
  },
  photo: {
    width: '12rem',
    height: '12rem',
    marginRight: '2rem'
  },
  photoActions: {
    height: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      lg: '2rem 2rem 0.5rem 2rem',
      xs: '1.5rem 1.5rem 0.5rem 1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: {
      lg: 'sticky',
      xs: 'relative',
    },
    top: {
      lg: '2rem'
    },
    ml: {
      lg: '2rem',
      xs: 0
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'auto'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      lg: '20rem',
      sm: '30rem',
      xs: '100%',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
} as const
