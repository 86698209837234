import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class NotificationStore {
  rootStore

  notifications: any = []
  notification: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateNotifications = (notifications: any) => { this.notifications = notifications }
  updateNotification = (notification: any) => { this.notification = notification }
  updateLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.updateNotifications([])
    this.updateNotification(null)
    this.updateLoading(false)
  }

  async getNotifications() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getNotifications()
      if (response.ok) {
        const notifications = response.data?.items || []
        this.updateNotifications(notifications)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getNotification(id: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getNotification(id)
      if (response.ok) {
        this.updateNotification(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createNotification(data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createNotification(data)
      if (response.ok) {
        setTimeout(() => this.getNotifications(), 500)
        showMessage(i18n.t('notification_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('notification_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateNotificationInfo(data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateNotification(this?.notification?.id, data)
      if (response.ok) {
        this.getNotifications()
        showMessage(i18n.t('notification_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('notification_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async uploadNotificationPhoto(file: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response: any = await Api.uploadNotificationPhoto(this?.notification?.id, file)
      if (response.ok) {
        const photoUrl = response.data?.url
        if (callback) {
          callback(photoUrl)
        }
      } else {
        showMessage(i18n.t('notification_photo_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveNotification(id: number) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveNotification(id)
      if (response.ok) {
        this.getNotifications()
        showMessage(i18n.t('notification_archived'))
      } else {
        showMessage(i18n.t('notification_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
