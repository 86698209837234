import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { validateNotificationInput } from '../../../Utils/validation'
import { NotificationTypes } from '../../../Utils/constants'
import AttachmentModal from '../../../Components/Common/AttachmentModal'
import TextEditor from '../../../Components/Common/TextEditor'
import TimeInput from '../../../Components/Common/TimeInput'
import RadioButton from '../../../Components/Common/RadioButton'
import { Colors } from '../../../Utils/theme'

const Notification = (props: any) => {
  const { sessionStore, notificationStore }: any = useStore()
  const { user } = sessionStore
  const { notification } = notificationStore

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [lang, setLang] = useState('fi')
  const [titleEn, setTitleEn] = useState('')
  const [type, setType] = useState('general')
  const [receivers, setReceivers] = useState('all')
  const [htmlEn, setHtmlEn] = useState('')
  const [linkType, setLinkType] = useState(null)
  const [linkTarget, setLinkTarget] = useState('')
  const [linkText, setLinkText] = useState('')
  const [photo, setPhoto] = useState<string | null>(null)
  const [video, setVideo] = useState<string | null>(null)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  const [attachmentType, setAttachmentType] = useState('')
  const [publishMode, setPublishMode] = useState('now')
  const [publishedAtTime, setPublishedAtTime] = useState<any>(null)
  const [publishedAtDate, setPublishedAtDate] = useState<any>(null)

  const openAddVideoAttachmentModal = () => {
    setShowAttachmentModal(true)
    setAttachmentType('video')
  }
  const closeVideoAttachmentModal = () => {
    setShowAttachmentModal(false)
    setAttachmentType('')
  }
  const updateVideo = (video: any) => {
    setVideo(video)
    closeVideoAttachmentModal()
  }

  const togglePublishImmediately = () => setPublishMode('now')
  const togglePublishTimed = () => setPublishMode('timed')

  useEffect(() => {
    notificationStore.getNotification(params.notificationId)
  }, [])

  useEffect(() => {
    if (notification) {
      setTitleEn(notification.titleEn || '')
      setHtmlEn(notification?.htmlEn || notification?.contentEn || '')
      setType(notification.type || 'general')
      setReceivers(notification.receivers || 'all')
      setLinkType(notification.linkType || null)
      setLinkTarget(notification.linkTarget || '')
      setLinkText(notification.linkText || '')
      setPhoto(notification.photo || null)
      setVideo(notification.video || null)
      setPublishedAtTime(DateTime.fromISO(notification.publishedAt).toFormat('HH:mm'))
      setPublishedAtDate(DateTime.fromISO(notification.publishedAt).toFormat('yyyy-MM-dd'))
      if (notification?.publishedAt) {
        setPublishMode('timed')
      }
    }
  }, [notification])

  useEffect(() => {
    setLinkTarget('')
    if (linkType === 'screen') {
      setLinkText('Open screen')
    } else {
      setLinkText('Open link')
    }
  }, [linkType])

  const getLinkTypeOptions = () => {
    return [
      { value: 'screen', label: t('app_screen') },
      { value: 'url', label: t('url') }
    ]
  }

  const getLinkTargetOptions = () => {
    return [
      { value: 'LatestThrowScreen', label: t('latest_throw_screen') },
      { value: 'LatestThrowSettingsScreen', label: t('latest_throw_settings_screen') },
      { value: 'ThrowsScreen', label: t('previous_throws_screen') },
      { value: 'DiscsScreen', label: t('discs_screen') },
      { value: 'ProfileScreen', label: t('profile_screen') },
      { value: 'EventsScreen', label: t('events_screen') },
    ]
  }

  const getTypeOptions = () => {
    return NotificationTypes.map((value: any) => {
      return { value, label: t(value) }
    })
  }

  const getReceiverOptions = () => {
    return [
      { value: 'all', label: t('all') }
    ]
  }
  const onUploadPhoto = useCallback((files: any) => {
    if (files && files?.length) {
      const file = files?.[0]
      const callback = (url: string) => setPhoto(url || null)
      notificationStore.uploadNotificationPhoto(file, callback)
    }
  }, [])

  const onDeletePhoto = () => setPhoto(null)

  const photoDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadPhoto
  })

  const save = () => {
    let publishedAt: any = null
    if (publishMode !== 'now') {
      publishedAt = DateTime
        .fromFormat(`${publishedAtDate} ${publishedAtTime}`, `yyyy-MM-dd HH:mm`)
        .toJSDate()
    }

    const notification: any = {
      type,
      titleEn,
      htmlEn,
      linkType: linkType || null,
      linkTarget: linkTarget || null,
      linkText: linkText || null,
      photo,
      video,
      receivers,
    }

    console.log(notification)
    if (!notification?.sentAt) {
      notification.publishedAt = publishedAt
    }
    notificationStore.updateNotificationInfo(notification)
  }

  const cancel = () => navigate(-1)

  const getIsSaveDisabled = () => {
    if (!receivers || receivers !== 'all') {
      return true
    }

    const isValid = validateNotificationInput({
      titleEn,
      type,
      receivers,
      htmlEn,
      publishMode,
      publishedAtTime,
      publishedAtDate,
      photo,
      video,
    })
    return !isValid
  }

  const renderCategorySelect = () => {
    return (
      <Select
        label={t('category')}
        options={getTypeOptions()}
        value={type}
        onChange={setType}
        width='24rem'
        mr='2rem'
      />
    )
  }

  const renderReceiverSelect = () => {
    return (
      <Select
        label={t('receivers')}
        options={getReceiverOptions()}
        value={receivers}
        onChange={setReceivers}
      />
    )
  }

  const renderLinkTargetSelect = () => {
    if (linkType) {
      if (linkType === 'screen') {
        return (
          <Select
            label={t('app_screen')}
            options={getLinkTargetOptions()}
            value={linkTarget}
            onChange={setLinkTarget}
            maxWidth='24rem'
            mt='1.25rem'
          />
        )
      }
      return (
        <Input
          label={t('url')}
          value={linkTarget}
          onChange={setLinkTarget}
          mt='1.25rem'
        />
      )
    }
    return null
  }

  const renderAttachmentActions = () => {
    if (photo) {
      return (
        <>
          <Box component='img' src={photo} sx={styles.photo} />
          <Box sx={{ ...styles.row, mb: '0rem' }}>
            <Button
              text={t('change_photo')}
              onClick={photoDrop.open}
              icon='image'
              backgroundColor={Colors.brandPrimary}
              width='100%'
              mr='2rem'
            />
            <Button
              text={t('remove_photo')}
              onClick={onDeletePhoto}
              icon='image'
              variant='secondary'
              width='100%'
            />
          </Box>
        </>
      )
    } else if (video) {
      return (
        <>
          <Box sx={styles.row}>
            <Typography variant='body1' mb='0.5rem' ml='0.5rem' fontSize='1.125rem'>
              {video}
            </Typography>
          </Box>
          {/* <Box component='video' src={video} sx={styles.photo} controls /> */}
          <Box sx={{ ...styles.row, mb: '0rem' }}>
            <Button
              text={t('change_video')}
              onClick={openAddVideoAttachmentModal}
              icon='video'
              backgroundColor={Colors.brandPrimary}
              width='100%'
              mr='2rem'
            />
            <Button
              text={t('remove_video')}
              onClick={() => setVideo(null)}
              icon='video'
              variant='secondary'
              width='100%'
            />
          </Box>
        </>
      )
    } else {
      return (
        <Box sx={{ ...styles.row, mb: '0rem' }}>
          <Button
            text={t('add_photo')}
            onClick={photoDrop.open}
            icon='image'
            variant='secondary'
            width='100%'
            mr='2rem'
          />
          <Button
            text={t('add_video')}
            onClick={openAddVideoAttachmentModal}
            icon='video'
            variant='secondary'
            width='100%'
          />
        </Box>
      )
    }
  }

  const renderAttachmentModal = () => {
    if (showAttachmentModal) {
      return (
        <AttachmentModal
          type={attachmentType}
          url={video}
          onClose={closeVideoAttachmentModal}
          onSave={updateVideo}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mb='1rem'>{t('edit_notification')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('title')}
            value={titleEn}
            onChange={setTitleEn}
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            {renderCategorySelect()}
            {renderReceiverSelect()}
          </Box>
          <TextEditor
            label={t('content')}
            value={htmlEn}
            onChange={setHtmlEn}
          />
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('linking')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={{ ...styles.row, mb: '0rem' }}>
            <Select
              label={t('link')}
              options={getLinkTypeOptions()}
              value={linkType}
              onChange={setLinkType}
              mr='2rem'
            />
            <Input
              label={t('link_button_text')}
              value={linkText}
              onChange={setLinkText}
            />
          </Box>
          {renderLinkTargetSelect()}
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('attachments')}</Typography>
        <Box sx={{ ...styles.formContainer, maxWidth: '30rem' }}>
          {renderAttachmentActions()}
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('send')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Box sx={styles.timesFormContainer}>
            <RadioButton
              label={t('immediately')}
              checked={publishMode === 'now'}
              onChange={togglePublishImmediately}
              mb='0.5rem'
              disabled={notification?.sentAt}
            />
            <RadioButton
              label={t('timed')}
              checked={publishMode === 'timed'}
              onChange={togglePublishTimed}
              mb='0.5rem'
              disabled={notification?.sentAt}
            />
            <Collapse in={publishMode !== 'now'}>
              {publishMode !== 'now' && (
                <Box sx={styles.timesContainer}>
                  <Input
                    value={publishedAtDate}
                    onChange={setPublishedAtDate}
                    width='10rem'
                    type='date'
                    disabled={notification?.sentAt}
                  />
                  <TimeInput
                    value={publishedAtTime}
                    onChange={setPublishedAtTime}
                    width='5.625rem'
                    disabled={notification?.sentAt}
                  />
                </Box>
              )}
            </Collapse>
          </Box>
          <Button
            text={t('save')}
            onClick={save}
            backgroundColor={Colors.brandPrimary}
            width='100%'
            mb='1.25rem'
            disabled={getIsSaveDisabled()}
          />
          <Button
            text={t('cancel')}
            onClick={cancel}
            variant='secondary'
            width='100%'
            mb='0.5rem'
          />
        </Box>
      </Box>
      {renderAttachmentModal()}
    </Box>
  )
}

export default observer(Notification)

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '54rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      lg: '2rem 2rem 2rem 2rem',
      xs: '1.5rem 1.5rem 1.5rem 1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: {
      lg: 'sticky',
      xs: 'relative',
    },
    top: {
      lg: '2rem'
    },
    ml: {
      lg: '2rem',
      xs: 0
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'auto'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      lg: '22rem',
      sm: '30rem',
      xs: '100%',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
  timesFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    backgroundColor: Colors.black20,
    p: '1rem 1.5rem 1.5rem 1.5rem',
    mb: '1.5rem'
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    mt: '1rem'
  },
  photo: {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
    maxHeight: '15rem',
    // height: 'auto',
    mb: '2rem'
  }
} as const
