import { useMemo } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Checkbox from './Checkbox'
import NoRowsOverlay from './NoRowsOverlay'
import Typography from '@mui/material/Typography'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { Colors } from '../../Utils/theme'

const Table = (props: any) => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const columns = useMemo(() => {
    if (props?.mobileColumns && !lgUp) {
      return props?.columns
        ?.filter((column: any) => props?.mobileColumns?.[smUp ? 'sm' : 'xs']?.includes(column.field))
        ?.map((column: any) => ({
          ...column,
          headerName: !lgUp ? (column?.mobileHeaderName ?? column?.headerName) : column?.headerName,
          maxWidth: !lgUp ?
            (column?.mobileMaxWidth?.[smUp ? 'sm' : 'xs'] ?? column?.maxWidth) :
            column?.maxWidth,
          minWidth: !lgUp ?
            (column?.mobileMinWidth?.[smUp ? 'sm' : 'xs'] ?? column?.minWidth) :
            column?.minWidth
        }))
    }
    return props.columns
  }, [props.columns, props.mobileColumns, lgUp, smUp])
  const rows = useMemo(() => props.rows, [props.rows])

  const getRowClassName = (params: any) => {
    if (params?.indexRelativeToCurrentPage % 2 === 0) {
      return 'even-dg-row'
    }
    return 'odd-dg-row'
  }

  const renderTitle = () => {
    if (props.title) {
      return (
        <Typography
          variant='h3'
          sx={{...styles.title, ...(props.titleSx ?? {})}}
        >
          {props.title ?? ''}
        </Typography>
      )
    }
    return null
  }

  return (
    <Box sx={{...styles.tableContainer, ...(props.sx ?? {})}}>
      {renderTitle()}
      <DataGrid
        apiRef={props.apiRef ?? null}
        columns={columns}
        rows={rows}
        sx={styles.table}
        columnHeaderHeight={!lgUp ? (smUp ? 56 : 48) : 64}
        rowHeight={!lgUp ? (smUp ? 56 : 48) : 64}
        getRowClassName={getRowClassName}
        slots={{
          baseCheckbox: Checkbox,
          noRowsOverlay: NoRowsOverlay
        }}
        getRowId={props.getRowId}
        checkboxSelection={false}
        // autoHeight={!props.loading && !rows?.length}
        hideFooter
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default Table

const styles = {
  tableContainer: {
    width: '100%',
    minHeight: {
      lg: 'calc(100vh - 10rem - 2.5rem - 3.125rem)',
      xs: 'calc(100vh - 8.5rem - 2.5rem - 3.125rem)'
    },
    maxHeight: {
      lg: 'calc(100vh - 10rem - 2.5rem - 3.125rem)',
      xs: 'calc(100vh - 8.5rem - 2.5rem - 3.125rem)'
    },
    backgroundColor: Colors.transparent,
    boxShadow: 0
  },
  table: {
    boxShadow: 0,
    '&, [class^=MuiDataGrid]': {
      border: 'none'
    },
    '& .MuiDataGrid-cell': {
      fontSize: '1rem',
      color: Colors.heading
    },
    '& .MuiDataGrid-cell:hover': {
    },
    '& .MuiDataGrid-cell--textLeft': {
      pl: '1.125rem'
    },
    '& .gameproofer-table-column--header': {
      backgroundColor: Colors.heading,
      borderTopLeftRadius: 0
    },
    '& .gameproofer-table--header': {
      backgroundColor: Colors.violet,
      color: Colors.text,
      // borderLeft: `1px solid ${Colors.border}`,
      pl: '1.125rem'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      fontSize: '1rem',
      // lineHeight: '1.125rem',
      color: Colors.white,
      textTransform: 'uppercase'
    },
    '& .gameproofer-table--header > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .gameproofer-table-column--header > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none',
    },
    '.MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
    },
    '.MuiDataGrid-sortIcon': {
      color: Colors.white,
      opacity: 'inherit !important',
    },
    '--DataGrid-overlayHeight': {
      height: '10rem'
    },
    '& .even-dg-row': {
      backgroundColor: Colors.transparent
    },
    '& .odd-dg-row': {
      backgroundColor: Colors.brandSecondaryDarker
    },
  },
  title: {
    mt: '2rem',
    mb: '1.25rem'
  }
}
