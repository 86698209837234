import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import ProfilePlaceholder from '../../../Assets/Images/profile-placeholder.png'
import { Button, Input, Select } from '../../../Components'
import { CountryOptions, SkillLevelOptions } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const MobileUser = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { mobileUserStore }: any = useStore()
  const { loading, user } = mobileUserStore

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')
  const [skillLevel, setSkillLevel] = useState('')
  const [pdgaNumber, setPdgaNumber] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive')
  }
  const openActivateConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('activate')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
  }

  useEffect(() => {
    const userId = params?.userId ?? null
    mobileUserStore.getMobileUser(userId)
  }, [])

  useEffect(() => {
    if (user && user?.id === Number(params?.userId)) {
      setFirstName(user?.firstName || '')
      setLastName(user?.lastName || '')
      setEmail(user?.email || '')
      setPhone(user?.phone || '')
      setCountry(user?.country || '')
      setSkillLevel(user?.skillLevel || '')
      setPdgaNumber(user?.pdgaNumber || '')
    }
  }, [user])

  const archiveUser = () => {
    mobileUserStore.archiveMobileUser(
      Number(params.userId),
      closeActionConfirmation
    )
  }

  const activateUser = () => {
    mobileUserStore.activateMobileUser(
      Number(params.userId),
      closeActionConfirmation
    )
  }

  const renderStatusActionButton = () => {
    if (user?.archivedAt) {
      return (
        <Button
          text={t('activate_user_account')}
          onClick={openActivateConfirmation}
          width='100%'
          variant='secondary'
          mb='0.5rem'
        />
      )
    } else {
      return (
        <Button
          text={t('close_user_account')}
          onClick={openArchiveConfirmation}
          width='100%'
          variant='secondary'
          mb='0.5rem'
        />
      )
    }
  }

  const renderConfirmationDialog = () => {
    if (showActionConfirmation) {
      const isArchive = actionType === 'archive'
      return (
        <ConfirmationDialog
          title={isArchive ? t('close_user_account') : t('activate_user_account')}
          content={isArchive ? t('close_mobile_user_account_info') : t('activate_mobile_user_account_info')}
          onClose={closeActionConfirmation}
          onAction={isArchive ? archiveUser : activateUser}
          actionText={isArchive ? t('close_account') : t('activate_account')}
          loading={loading}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Box sx={styles.photoContainer}>
          <Avatar src={user?.photo || ProfilePlaceholder} sx={styles.photo} />
        </Box>
        <Typography variant='h2' mb='1rem'>{t('general_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={() => {}}
              maxWidth='15rem'
              mr='2rem'
              disabled
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={() => {}}
              disabled
            />
          </Box>
          <Box sx={styles.row}>
            <Input
              label={t('email')}
              value={email}
              onChange={() => {}}
              mr='2rem'
              copy
              disabled
            />
            <Input
              label={t('phone')}
              value={phone}
              onChange={() => {}}
              maxWidth='14rem'
              disabled
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('skill_level')}
              options={SkillLevelOptions}
              value={skillLevel}
              onChange={() => {}}
              mr='2rem'
              disabled
            />
            <Input
              label={t('pdga_number')}
              value={pdgaNumber}
              onChange={() => {}}
              disabled
            />
          </Box>
          <Select
            label={t('country')}
            options={CountryOptions}
            value={country}
            onChange={() => {}}
            maxWidth='24rem'
            mb='1.5rem'
            disabled
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          {renderStatusActionButton()}
        </Box>
      </Box>
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(MobileUser)

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '47rem',
    overflowY: 'auto',
    pt: '2rem',
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    mt: '1rem',
    mb: '2rem'
  },
  photo: {
    width: '12rem',
    height: '12rem',
    marginRight: '2rem'
  },
  photoActions: {
    height: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    p: {
      lg: '2rem 2rem 0.5rem 2rem',
      xs: '1.5rem 1.5rem 0.5rem 1.5rem',
    },
    backgroundColor: Colors.brandSecondaryDarker,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: {
      lg: 'sticky',
      xs: 'relative',
    },
    top: {
      lg: '2rem'
    },
    ml: {
      lg: '2rem',
      xs: 0
    },
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'auto'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: {
      lg: '20rem',
      sm: '30rem',
      xs: '100%',
    },
    backgroundColor: Colors.brandSecondaryDarker,
    borderRadius: '0.625rem',
    p: '1.5rem 1.5rem 1rem 1.5rem',
    mb: '1.5rem',
  },
} as const
