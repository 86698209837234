import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Input from './Input'
import Button from './Button'
import Select from './Select'
import MultiSelect from './MultiSelect'
import IconButton from './IconButton'
import { Colors } from '../../Utils/theme'

const TableActions = (props: any) => {
  const { t } = useTranslation()

  const renderSearchInput = () => {
    if (props?.updateSearch) {
      return (
        <Input
          placeholder={props?.searchPlaceholder || t('search_by_name')}
          value={props.searchKey}
          onChange={props.updateSearch}
          onAction={props?.clearSearch}
          iconSx={styles.clearIcon}
          sx={styles.searchInput}
          mr='1.5rem'
          search
        />
      )
    }
    return null
  }

  const renderFilterButton = () => {
    if (props?.onFilterClick) {
      return (
        <Button
          text={t('filter')}
          onClick={props.onFilterClick}
          icon='sort'
          sx={styles.actionButton}
          width='14rem'
        />
      )
    }
    return null
  }

  const renderSelectColumns = () => {
    if (props?.onSelectColumns) {
      return (
        <MultiSelect
          options={props.columnOptions}
          value={props.columns}
          onChange={props.onSelectColumns}
          placeholder={t('columns')}
          sx={styles.select}
          mr='1.5rem'
          showCountAsValue
        />
      )
    }
    return null
  }

  const renderCsvDownload = () => {
    if (props?.onCsvDownload) {
      return (
        <Button
          text={t('download_csv')}
          icon='excel'
          onClick={props.onCsvDownload}
          disabled={props?.csvDisabled}
          variant='secondary'
          width='11.5rem'
          ml='1.5rem'
        />
      )
    }
    return null
  }

  const renderBulkActionSelect = () => {
    if (props?.onBulkActionSelect) {
      return (
        <Select
          placeholder={t('bulk_actions')}
          options={props.bulkActionOptions}
          value={props.bulkAction}
          onChange={props.onBulkActionSelect}
          sx={styles.bulkActionSelect}
          ml='1.5rem'
        />
      )
    }
    return null
  }

  const renderAddButton = () => {
    if (props?.onAddClick) {
      return (
        <Button
          text={props?.addText || t('add_new')}
          onClick={props.onAddClick}
          sx={styles.actionButton}
        />
      )
    }
    return null
  }

  const renderTimePeriodActions = () => {
    if (props?.selectedTimePeriod) {
      return (
        <Box sx={{ ...styles.row, mb: 0 }}>
        <IconButton
          icon='arrowLeft'
          onClick={props?.toPreviousTimePeriod}
          sx={styles.previousButton}
          iconSize='1.125rem'
        />
        <Box sx={styles.timePeriod}>
          {props?.selectedTimePeriod}
        </Box>
        <IconButton
          icon='arrowRight'
          onClick={props?.toNextTimePeriod}
          sx={styles.nextButton}
          iconSize='1.125rem'
          disabled={props?.nextDisabled}
        />
      </Box>
      )
    }
    return null
  }

  return (
    <Box sx={styles.actions}>
      <Box sx={styles.row}>
        {renderSearchInput()}
        {/* renderFilterButton() */}
        {renderSelectColumns()}
      </Box>
      <Box sx={styles.actionsRow}>
        {renderBulkActionSelect()}
        {renderTimePeriodActions()}
        {renderCsvDownload()}
        {renderAddButton()}
      </Box>
    </Box>
  )
}

export default observer(TableActions)

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    p: '1.25rem 0rem',
    paddingTop: '0rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '30rem',
    // flexGrow: 1
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  searchInput: {
    minWidth: {
      xxl: '22rem',
      xs: '19rem'
    },
  },
  actionButton: {
    height: '3.125rem',
    width: {
      sm: '11rem',
      xs: '9.5rem'
    },
    padding: {
      xxl: '0rem 2.5rem',
      xs: '0rem 1.875rem'
    },
    ml: {
      sm: '1.5rem',
      xs: '0.5rem'
    }
  },
  clearIcon: {
    top: '0.675rem',
    right: '1rem',
    width: '1.675rem'
  },
  bulkActionSelect: {
    width: {
      xxl: '16rem',
      xl: '15rem',
      xs: '12rem'
    }
  },
  select: {
    alignSelf: 'flex-start',
    minWidth: {
      xs: '14rem'
    }
  },
  previousButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    justifyContent: 'center',
    ml: '1rem',
    mr: '0.5rem',
  },
  nextButton: {
    height: '3.125rem',
    width: '3.125rem',
    minWidth: '3.125rem',
    ml: '0.5rem',
  },
  timePeriod: {
    width: '100%',
    minWidth: '10.5rem',
    height: '3.125rem',
    fontSize: '1.125rem',
    color: Colors.text,
    backgroundColor: Colors.brandSecondary,
    borderRadius: '0.625rem',
    borderColor: Colors.border,
    padding: '0rem 1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
} as const
